

import React from "react";
import './vmts04.css';
import { getTemplateData, houseData, getStatusColors } from '../../../global-elements/globals.js';

export default function Vmts04(props) {
    return (
        <div id="vmts-04" className="slide rotation">

            <div className="container">
                <div className="content-slide">
                    <div className="greyBox" style={{ backgroundColor: props.colors.primary_color }}>
                        <div className="address" style={{ color: props.colors.secondary_text_color }}>
                            <span>{houseData[0].address},   </span>
                            <span>{props.organisation.location}</span>
                        </div>
                        <div className="mainImageContainer">
                            <div className="status" style={getStatusColors(props.colors, houseData[0].status)}>{houseData[0].status}</div>
                            <div className="bg-img" style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }}></div>
                        </div>

                        <div className="price" style={{ backgroundColor: props.colors.tertiary_color, color: props.colors.tertiary_text_color }}>{houseData[1].price}</div>



                        <div className="text-container">
                            <div className="boxTable" style={{ 'color': props.colors.secondary_text_color }}>
                                <div className="overWriteBorderBottom"
                                    style={{ backgroundColor: props.colors.secondary_color }}></div>
                                <div className="boxTableItem">
                                    <span className="js-ka-k">{getTemplateData(0, 0)[0]}</span>
                                    <span className="js-ka-a">{getTemplateData(0, 0)[1]}</span>
                                </div>

                                <div className="boxTableItem">
                                    <span className="js-ka-k">{getTemplateData(0, 1)[0]}</span>
                                    <span className="js-ka-a">{getTemplateData(0, 1)[1]}</span>
                                </div>

                                <div className="boxTableItem">
                                    <span className="js-ka-k">{getTemplateData(0, 2)[0]}</span>
                                    <span className="js-ka-a">{getTemplateData(0, 2)[1]}</span>
                                </div>

                                <div className="boxTableItem">
                                    <span className="js-ka-k">{getTemplateData(0, 3)[0]}</span>
                                    <span className="js-ka-a">{getTemplateData(0, 3)[1]}</span>
                                </div>

                                <div className="boxTableItem">
                                    <span className="js-ka-k">{getTemplateData(0, 4)[0]}</span>
                                    <span className="js-ka-a">{getTemplateData(0, 4)[1]}</span>
                                </div>

                                <div className="boxTableItem">
                                    <span className="js-ka-k">{getTemplateData(0, 5)[0]}</span>
                                    <span className="js-ka-a">{getTemplateData(0, 5)[1]}</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="sideImagesContainer">
                        <div className="s-img" style={{ 'backgroundImage': 'url(' + houseData[1].media[1] + ')' }}></div>
                        <div className="s-img right" style={{ 'backgroundImage': 'url(' + houseData[1].media[2] + ')' }}></div>
                        <div className="s-img bottom" style={{ 'backgroundImage': 'url(' + houseData[1].media[3] + ')' }}></div>
                        <div className="s-img bottom right" style={{ 'backgroundImage': 'url(' + houseData[1].media[4] + ')' }}></div>
                    </div>
                </div>
                <div className="logoContainer">
                    <img className="logo" src={props.organisation.logos[props.colors.template_logo_nr]} alt="" />
                </div>

            </div>
            <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
        </div>
    );
}


