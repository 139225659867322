import React from "react";

import house1_1 from './../../assets/house-images/Huis-1-1.jpg';
import house1_2 from './../../assets/house-images/Huis-1-2.jpg';
import house1_3 from './../../assets/house-images/Huis-1-3.jpg';
import house1_4 from './../../assets/house-images/Huis-1-4.jpg';
import house1_5 from './../../assets/house-images/Huis-1-4.jpg';
import house1_map from './../../assets/house-images/Huis-1-maps.jpg';

import house2_1 from './../../assets/house-images/Huis-2-1.jpg';
import house2_2 from './../../assets/house-images/Huis-2-2.jpg';
import house2_3 from './../../assets/house-images/Huis-2-3.jpg';
import house2_4 from './../../assets/house-images/Huis-2-4.jpg';
import house2_5 from './../../assets/house-images/Huis-2-5.jpg';
import house2_map from './../../assets/house-images/Huis-2-maps.jpg';

import house3_1 from './../../assets/house-images/Huis-3-1.jpg';
import house3_2 from './../../assets/house-images/Huis-3-2.jpg';
import house3_3 from './../../assets/house-images/Huis-3-3.jpg';
import house3_4 from './../../assets/house-images/Huis-3-4.jpg';

import house4_1 from './../../assets/house-images/Huis-4-1.jpg';
import house4_2 from './../../assets/house-images/Huis-4-2.jpg';
import house4_3 from './../../assets/house-images/Huis-4-3.jpg';
import house4_4 from './../../assets/house-images/Huis-4-4.jpg';

export const possibleFontsArray = ["Open Sans", "Cursive", "Monospace", "Roboto", "Helvetica", "Times New Roman", "Montserrat", "Raleway", "Ubuntu", "Source sans pro"].sort();
export const possibleTemplatesInOrder = ["vmts00", "vmts01", "vmts02", "vmts03", "vmts04", "vmt00", "vmt01", "vmt05", "vmt09", "vmt10", "vmt13", "vmt15", "vmt49", "vmt02", "vmt03", "vmt04"];


export function getDefaultLogo(template){
    switch(template){ 
        case 'vmts04':
        case 'vmt10':
        case 'vmt13':
        case 'vmt05':
        case 'vmt02':
            return '0'; // Witte achtergrond, opgeschreven als logo 1
        // case 'vmt09':
            // return '2'; //Grijze achtergonrd , opgeschreven als logo 3
        default: 
            return '1'; //Gekleurde achtergrond , opgeschreven als logo 2
    }
}

export function getStatusColors(selectedStyling, status) {
    switch (status.toLowerCase()) {
        case "te koop":
            return { backgroundColor: selectedStyling.label_forsale_color, color: selectedStyling.label_forsale_text_color }
        case "verkocht":
            return { backgroundColor: selectedStyling.label_sold_color, color: selectedStyling.label_sold_text_color }
        default: break;
    }
}


function getRandom(array) {
    return array[Math.floor(Math.random() * array.length)];
}

function getRandomBetween(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const houseData = [
    {
        'address': 'Kanaalweg 12',
        'usable_area': 225,
        'plot_area': 587,
        'bedrooms': 5,
        'energy_label': 'A',
        'type': 1, //Vrijstaande woning
        'year_built': '2018',
        'price': '€875.000, - k.k.',

        "status": "Te koop",
        "volume_area": 300,
        "is_furnished": getRandom([0, 1]),
        "is_upholstered": getRandom([0, 1]),
        "number_of_rooms": getRandomBetween(3, 8),

        "media": [
            house1_1,
            house1_2,
            house1_3,
            house1_4,
            house1_5
        ],
        "map": house1_map
    },
    {
        'address': 'Hoofdstraat 23',
        'usable_area': 560,
        'plot_area': 3400,
        'bedrooms': 8,
        'energy_label': 'A',
        'type': 1, //Vrijstaande woning
        'year_built': 1907,
        'price': '€1.250.000,- k.k.',

        "status": "Verkocht",
        "volume_area": 300,
        "is_furnished": getRandom([0, 1]),
        "is_upholstered": getRandom([0, 1]),
        "number_of_rooms": getRandomBetween(3, 8),

        "media": [
            house2_1,
            house2_2,
            house2_3,
            house2_4,
            house2_5
        ],
        "map": house2_map
    },
        {
        'address': 'Straatweg 6',
        'usable_area': 203,
        'plot_area': 394,
        'bedrooms': 4,
        'energy_label': 'B',
        'type': 1, //Eengezinswoning
        'year_built': 2005,
        'price': '€725.000,- k.k.',

        "status": "Te koop",
        "volume_area": 300,
        "is_furnished": getRandom([0, 1]),
        "is_upholstered": getRandom([0, 1]),
        "number_of_rooms": getRandomBetween(3, 8),

        "media": [
            house3_1,
            house3_2,
            house3_3,
            house3_4,
        ],
        "map": "",
        },
        {
        'address': 'Molenstraat 70',
        'usable_area': 154,
        'plot_area': 250,
        'bedrooms': 4,
        'energy_label': 'B',
        'type': 1, //hoekwoning
        'year_built': 2008,
        'price': '€515.000,- k.k.',

        "status": "Verkocht",
        "volume_area": 300,
        "is_furnished": getRandom([0, 1]),
        "is_upholstered": getRandom([0, 1]),
        "number_of_rooms": getRandomBetween(3, 8),

        "media": [
            house4_1,
            house4_2,
            house4_3,
            house4_4,
        ],
        "map": "",
    },
]


export function getTemplateData(houseNr, element) {
    const singleHouseData = houseData[houseNr];

    const chosenKeyArray = ["year_built", "usable_area", "energy_label", "number_of_rooms", "type", "plot_area", "is_furnished", "is_upholstered"];

    switch (chosenKeyArray[element]) {
        case "year_built":
            if (isValidValue(singleHouseData.year_built)) return (["Bouwjaar:", singleHouseData.year_built]); break;
        case "usable_area":
            if (isValidValue(singleHouseData.usable_area)) return ([<>Oppervlakte:</>, <>{singleHouseData.usable_area} m<sup>2</sup></>]); break;
        case "volume_area":
            if (isValidValue(singleHouseData.volume_area)) return ([<>Inhoud:</>, <>{singleHouseData.volume_area} m<sup>3</sup></>]); break;
        case "energy_label":
            if (isValidValue(singleHouseData.energy_label)) return (["Energielabel:", singleHouseData.energy_label]); break;
        case "number_of_rooms":
            if (isValidValue(singleHouseData.number_of_rooms)) return (["Kamers:", singleHouseData.number_of_rooms]); break;
        case "plot_area":
            if (isValidValue(singleHouseData.plot_area)) return ([<>Perceel:</>, <>{singleHouseData.plot_area} m<sup>2</sup></>]); break;
        case "type":
            if (singleHouseData.type) return (["Soort object:", translateHouseType(singleHouseData.type)]); break;
        case "bedrooms":
            if (isValidValue(singleHouseData.bedrooms)) return (["Slaapkamers:", singleHouseData.bedrooms]); break;
        case "is_furnished":
            if (isValidValue(singleHouseData.is_furnished)) return (["Gemeubileerd:", singleHouseData.is_furnished ? "Ja" : "Nee"]); break;
        case "is_upholstered":
            if (isValidValue(singleHouseData.is_upholstered)) return (["Gestoffeerd:", singleHouseData.is_upholstered ? "Ja" : "Nee"]); break;
        default: break;
    }


    function isValidValue(value = null) {
        if (value > '0') {
            return true;
        }
        return false;
    }

    function translateHouseType(type = null) {
        return ["Woning", "Appartement", "Parkeerplaats", "Bouwgrond", "Kantoor", "Bedrijfshal", "Overig prive", "Overig BOG", "Overig"][type - 1]
    }
}