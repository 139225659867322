import React from "react";
import './color-selector.css';

const ColorSelector = (props) => {
    // const [currentColorInHex, setCurrentColorInHex] = useState(props.defaultValue);

    function handleChange(event) {
        props.updateStylingFieldFunction(props.id, event.target.value);
    }

    return (
        <div className="ColorSelector">
            <div className="title">{props.name}</div>
            <input type="text" value={props.value} placeholder="Hexadecimaal kleur" maxLength={7} minLength={1} onChange={handleChange} />
            <input type="color" value={props.value} onChange={handleChange} />
        </div>
    );
}

export default ColorSelector;