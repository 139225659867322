import React, { useState } from "react";
import './admin-page.css';
import Topbar1 from "../global-elements/topbar1.js"
import { connect } from "react-redux";
import axios from 'axios';

function AdminPage(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [organisationArray, setOrganisationArray] = useState(false)
    const [selectedOrganisation, setSelectedOrganisation] = useState(false)
    const [showLogos, setShowLogos] = useState(false)
    const [loadedLogos, setLoadedLogos] = useState({});

    function loadListData(passWord){

            if(!organisationArray){
            axios.get(`https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/${passWord}.json`)
            .then((response) => {
                console.log(response.data)
                setOrganisationArray(response.data)
                setIsLoggedIn(true)
            }).catch(error => {
                console.log(error)
                // setIsDataLoaded(false)
            });
        } 
    }


    function get3Avatars(url_name, e) {
        //Check if logos are already in loadedLogos, check for key
        let logos = [false,false,false];
        if(url_name in loadedLogos){
            logos = loadedLogos[url_name]
            return [ logos[0], logos[1], logos[2]]
        }
        else {
               e.target.innerText = "Logos ophalen"
                e.target.style.pointerEvents = "none";

            axios.get(`https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/organisations/${url_name}/organisation/logos.json`)
            .then((response) => {
                if(response.data) logos = response.data;
                
                // Als nu ingeladen is kan het niet ververst worden hellaas
                setLoadedLogos({...loadedLogos, [url_name]:logos})
                setShowLogos(true)
                e.target.style.pointerEvents = "auto";
                e.target.innerText = "Ga naar logos veranderen"
                return [ logos[0], logos[1], logos[2]]
            }).catch(error => {
                e.target.style.pointerEvents = "auto";
                e.target.innerText = "Ga naar logos veranderen"
                console.log(error)
                // setIsDataLoaded(false)
            });
        }
    }

    const uploadAvatarChange = async (e,i, url_name) => {
        // All3AtOnce??
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        document.getElementById('img'+i).src = base64;
      
         axios.patch(`https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/organisations/${url_name}/organisation/logos.json`, {
            [i]: base64
        }).then(function (res) {
            alert("logo "+(i+1)+" aangepast")
        }).catch(function (error) {
            console.log(error.response);
        });
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    function selectOrganisation(url_name){
        if(url_name === selectedOrganisation){
            setShowLogos(false);
            setSelectedOrganisation(false); 
        } 
        else setSelectedOrganisation(url_name)   
    }
    
    function setShowLogosF(url_name,e){
        get3Avatars(url_name, e)
        if(showLogos) setShowLogos(false)
        else setShowLogos(url_name)
    }

    if(!isLoggedIn){
        return(
          <div className="AdminPage">
                <div className="inputDiv">
                    <input type="text" name="password" id="password_input" placeholder="wachtwoord" />
                    <button onClick={()=>loadListData(document.getElementById('password_input').value)}>Haal data op</button>
                </div>
          </div>
        )
    }

    // changeName() kan later
    else{
        return (
            //Eerst testscherm met alleen inputveld en "haal data op"
            <div className="AdminPage">
                <Topbar1></Topbar1>
        
            {/* {organisationArray? organisationArray[2].organisation  : "Nee"}     */}
            {Object.keys(organisationArray).map((url_name, i) => {
                return ( 

                <div className="organisationBlock" key={i}>
                    <div className="number">{i+1}</div>
                    <div className="title" onClick={()=>selectOrganisation(url_name)}>{url_name}</div>
                    {
                      selectedOrganisation === url_name ?
                        <div className={"moreinfo "+url_name}>

                            <div className="linkDiv" onClick={()=> window.open('/'+url_name, '_blank').focus()}>Ga naar Pagina</div>
                            <div className="openChangeImage" onClick={(e)=>setShowLogosF(url_name,e)}>Ga naar logos veranderen</div>

                            {
                                url_name in loadedLogos && showLogos
                                ?   

                                    <div className="logos">
                                        <img id="img0" className="whiteBg" src={loadedLogos[url_name][0]}  alt="Foto 1" onClick={()=>document.getElementById('fileUpload0').click()} />
                                        <img id="img1" className="colorBg" src={loadedLogos[url_name][1]} alt="Foto 2" onClick={()=>document.getElementById('fileUpload1').click()} /> 
                                        <img id="img2"className="greyBg" src={loadedLogos[url_name][2]} alt="Foto 3" onClick={()=>document.getElementById('fileUpload2').click()}/> 
                                        <input type="file" id="fileUpload0" name="diapositief" onChange={(e) => {uploadAvatarChange(e, 0, url_name);}} accept="image/png, image/gif, image/jpeg, image/svg+xml"/>
                                        <input type="file" id="fileUpload1" name="diapositief" onChange={(e) => {uploadAvatarChange(e, 1, url_name);}} accept="image/png, image/gif, image/jpeg, image/svg+xml"/>
                                        <input type="file" id="fileUpload2" name="diapositief" onChange={(e) => {uploadAvatarChange(e, 2, url_name);}} accept="image/png, image/gif, image/jpeg, image/svg+xml"/>

                                    </div>
                                :
                                    null
                            }
                        </div>  
                    :
                        null
                    }
                </div>
                )
                })
            }
            <a className="toCreate" href="/create">Organisatie toevoegen</a>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedOrientations: state.selectedOrientations,
    }
}

export default connect(mapStateToProps)(AdminPage);