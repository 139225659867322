

import React from "react";
import './vmts02.css';
import { getTemplateData, houseData, getStatusColors } from '../../../../pages/global-elements/globals.js';

function Vmts02(props) {
	return (
		<div id="vmts-02" className="slide rotation">
			<div className="container">
				<div className="header-slide" style={{ backgroundColor: props.colors.primary_color }}>
					<img className="logo" src={props.organisation.logos[props.colors.template_logo_nr]} alt="" />
				</div>
				<div className="content-slide">
					<div className="b-house house">
						<div className="picture-container">
							<div className="main-picture">
								<div className="status for-sale" style={getStatusColors(props.colors, houseData[0].status)}>
									<span>{houseData[0].status}</span>
								</div>
								<div className="bg-img" style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }} >
								</div>
							</div>
							<div className="side-pictures">
								<div className="s-img s-img-top" style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}
								>
								</div>
								<div className="s-img s-img-bottom" style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }}
								>
								</div>
							</div>
						</div>
						<div className="text-container">
							<div className="address" style={{ color: props.colors.primary_text_color }}>
								<span>{houseData[0].address}, </span>
								<span>{props.organisation.location}</span>
							</div>
							<div className="price" style={{ color: props.colors.primary_text_color }}>{houseData[0].price} </div>
							<table style={{ color: props.colors.primary_text_color }}>{/* Was originally tertiary_text_color */}
								<tbody>
									<tr>
										<th className="js-ka-k">{getTemplateData(0, 0)[0]}</th>
										<td className="js-ka-a">{getTemplateData(0, 0)[1]}</td>
									</tr>
									<tr>
										<th className="js-ka-k">{getTemplateData(0, 1)[0]}</th>
										<td className="js-ka-a">{getTemplateData(0, 1)[1]}</td>
									</tr>
									<tr>
										<th className="js-ka-k">{getTemplateData(0, 2)[0]}</th>
										<td className="js-ka-a">{getTemplateData(0, 2)[1]}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="s-houses">
						<div className="house">
							<div className="picture-container">
								<div className="main-picture">
									<div
										className="status" style={getStatusColors(props.colors, houseData[1].status)}
									><span
									>{houseData[1].status}</span>
									</div>
									<div className="bg-img" style={{ backgroundImage: 'url(' + houseData[1].media[0] + ')' }}></div>
								</div>
								<div className="bottom-pictures">
									<div className="s-img s-img-left" style={{ backgroundImage: 'url(' + houseData[1].media[1] + ')' }}></div>
									<div className="s-img s-img-right" style={{ backgroundImage: 'url(' + houseData[1].media[2] + ')' }}></div>
								</div>
							</div>
							<div className="text-container">
								<div className="address" style={{ color: props.colors.primary_text_color }}>
									<span>{houseData[1].address},  </span>
									<span>{props.organisation.location}</span>
								</div>
								<div className="price" style={{ color: props.colors.primary_text_color }}>{houseData[1].price} </div>
								<table style={{ color: props.colors.primary_text_color }}>{/* Was originally tertiary_text_color */}
									<tbody>
										<tr>
											<th className="js-ka-k">{getTemplateData(1, 0)[0]}</th>
											<td className="js-ka-a">{getTemplateData(1, 0)[1]}</td>
										</tr>
										<tr>
											<th className="js-ka-k">{getTemplateData(1, 1)[0]}</th>
											<td className="js-ka-a">{getTemplateData(1, 1)[1]}</td>
										</tr>
										<tr>
											<th className="js-ka-k">{getTemplateData(1, 2)[0]}</th>
											<td className="js-ka-a">{getTemplateData(1, 2)[1]}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="house">
							<div className="picture-container">
								<div className="main-picture">
									<div
										className="status rented" style={getStatusColors(props.colors, houseData[2].status)}
									><span
									>{houseData[2].status}</span>
									</div>
									<div className="bg-img" style={{ backgroundImage: 'url(' + houseData[2].media[0] + ')' }}
									>
									</div>
								</div>
								<div className="bottom-pictures">
									<div className="s-img s-img-left" style={{ backgroundImage: 'url(' + houseData[2].media[1] + ')' }}
									>
									</div>
									<div className="s-img s-img-right" style={{ backgroundImage: 'url(' + houseData[2].media[2] + ')' }}
									>
									</div>
								</div>
							</div>
							<div className="text-container">
								<div className="address" style={{ color: props.colors.primary_text_color }}>
									<span>{houseData[2].address},  </span>
									<span>{props.organisation.location}</span>
								</div>
								<div className="price" style={{ color: props.colors.primary_text_color }}> {houseData[2].price}</div>
								<table style={{ color: props.colors.primary_text_color }}>{/* Was originally tertiary_text_color */}
									<tbody>
										<tr>
											<th className="js-ka-k">{getTemplateData(2, 0)[0]}</th>
											<td className="js-ka-a">{getTemplateData(2, 0)[1]}</td>
										</tr>
										<tr>
											<th className="js-ka-k">{getTemplateData(2, 1)[0]}</th>
											<td className="js-ka-a">{getTemplateData(2, 1)[1]}</td>
										</tr>
										<tr>
											<th className="js-ka-k">{getTemplateData(2, 2)[0]}</th>
											<td className="js-ka-a">{getTemplateData(2, 2)[1]}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
					</div>
				</div>
			</div>
			<img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
		</div>
	);
}



export default Vmts02;
