import React from "react";
import './vmt49.css';
import { houseData, getStatusColors } from '../../../global-elements/globals.js'

export default function Vmt49(props) {

	return (
		<div id="vmt-49" className="slide rotation">
			<div className="container" style={{ backgroundColor: props.colors.background_color }}>
				{/* <!-- BEGIN FIRST HOUSE --> */}
				<div className="row no-gutters firsthouse">
					<div className="col-xs-12">
						<div className="overflow">
							{/* <!-- Animatie: shrink-animation is hier weggehaald --> */}
							<div className="house0"
								style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }}
							></div>
						</div>
						<div className="status-block" style={{ backgroundColor: props.colors.background_color }}>
							<div className="status" style={getStatusColors(props.colors, houseData[0].status)}>
								<span>{houseData[0].status}</span>
							</div>


						</div>
						<div className="address-price-block">
							<div className="address-details" style={{ color: props.colors.secondary_text_color }}>
								<span className="street"> {houseData[0].address}, </span><span className="city">{props.organisation.location}</span>
								<div className="cborder" style={{ backgroundColor: props.colors.secondary_color }} >
								</div>
							</div>
							<div className="price" style={{ color: props.colors.secondary_text_color }}>
								{/* <!--{{ slide.data[0].price | currency: "€" }}--> */}
								{houseData[0].price}
							</div >
						</div >
						<div className="house-block" style={{ backgroundColor: props.colors.primary_color }}>
							<div className="house1"
								style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}
							></div>
						</div >

						<div className="energy" style={{ color: props.colors.tertiary_text_color }}>

							Energielabel: {houseData[0].energy_label}
						</div >

						<div className="bottom-shadow"></div>
					</div >
				</div >



			</div >
			<img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
		</div >
	);
}