import React from "react";
import './topbottombar.css';
import logo from '../../assets/viewie-logo-tekst-donker.svg'
import { connect } from "react-redux";
import { selectOrientations } from "../../store/actions";

import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

function Topbar(props) {
    // const [selectedOrientations, selectOrientation] = useState('landscape');
  
    // function changeOrientation(newOrientation){
    //     // selectOrientation(newOrientation);
    //     props.selectOrientation(newOrientation);
    // }
 
  return (
    <div className="topbar1">
      {false && props.selectedOrganisation.url_name ? //False to prevent custommers from acidentally skipping the new save button 
        <Link to={"/" + props.selectedOrganisation.url_name}>
          <img className="logo" src={logo} alt="" />
        </Link>
        : 
          <img className="logo" src={logo} alt="" />
        }
    </div>
  );
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
        selectOrientations: selectOrientations,
    },
    dispatch
  );
}
function mapStateToProps(state) {
    return {
        selectedOrganisation: state.selectedOrganisation,
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(Topbar);



