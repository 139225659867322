




import React from "react";
import './vmt15.css';
import { getTemplateData, houseData, getStatusColors } from '../../../../pages/global-elements/globals.js'

export default function Vmt15(props) {
    return (
        <div id="vmt-15" className="slide rotation">
            <div className="container" style={{ backgroundColor: props.colors.background_color }}>

                <div className="firsthouse">
                    <div className="row header">
                        <div className="col-12 col-xl-6 col-left">
                            <div className="status" style={getStatusColors(props.colors, houseData[0].status)}>
                                <span>{houseData[0].status}</span>
                            </div>
                            <div className="overflow borders">
                                <div
                                >
                                    <div className="shrink-animation house1 bg-img"
                                        style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }}></div>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="address-price-block"
                                    style={{ backgroundColor: props.colors.primary_color }}>
                                    <div className="row no-gutters align-items-center">
                                         {/* <div className="col-1">
                                            <div className="marker">
                                                <svg version="1.1" id="marker" xmlns="http://www.w3.org/2000/svg"
                                            x="0px" y="0px"
                                            viewBox="0 0 200 258" style="enable-background:new 0 0 200 258;"
                                            xml:space="preserve">
                                            <path style={{ 'fill': props.colors.secondary_color}} d="M0,13.9C0,6.2,6.3,0,14,0h172c7.7,0,14,6.2,14,13.9v171.2c0,7.7-6.2,13.9-14,13.9h-43.2L100,258l-42.9-59H14
                                            c-7.7,0-14-6.2-14-13.9V13.9z" />
                                            <path style={{ 'fill': props.colors.primary_color}}
                                                d="M180.2,92.9l-78.2-66.1c-1.2-1-3-1-4.3,0L19.4,92.9c-1,0.9-1.4,2.3-0.9,3.5c0.5,1.2,1.7,2.1,3,2.1h19.7v66.7
                                            c0,1.7,1.5,3.2,3.2,3.2h110.7c1.8,0,3.2-1.4,3.2-3.2V98.5H178c1.4,0,2.6-0.8,3-2.1C181.6,95.2,181.2,93.8,180.2,92.9 M120.3,158.7
                                            H79.3v-51.2c0-11,9.2-19.9,20.5-19.9s20.5,8.9,20.5,19.9L120.3,158.7L120.3,158.7z M152.5,92.4c-1.7,0-3.1,1.3-3.1,3v63.5h-22.9
                                            v-51.4c0-14.3-12-26-26.6-26c-14.7,0-26.6,11.7-26.6,26v51.4H50.2V95.4c0-1.7-1.4-3-3.1-3H33.6l66.2-55.9L166,92.4H152.5z" />
                                        </svg> @#@ 
                                            </div>
                                        </div>*/}
                                        <div className="col-11 pl-2">
                                            <div className="address-details d-flex flex-wrap"
                                                style={{ color: props.colors.secondary_text_color }}>
                                                <div className="street ellipsis">{houseData[0].address}</div>

                                                <div className="city ellipsis">{props.organisation.location}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bordertop"
                                        style={{ backgroundColor: props.colors.background_color }}></div>
                                    <div className="row no-gutters align-items-center position-relative">
                                        <div className="col-6">
                                            <div className="price-block"
                                                style={{ color: props.colors.secondary_text_color }}>
                                                <div className="arrow-right"
                                                    style={{ borderLeftColor: props.colors.background_color }}>
                                                </div>
                                                {/* <div             className="ask-price">
                                            {{ slide.data[0].sale_type == '2'  ? 'Huurprijs:' : 'Vraagprijs:' }}</div>@#@ */}
                                                <div className="price">
                                                    <div
                                                    >
                                                        {houseData[0].price}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="middleborder"
                                            style={{ backgroundColor: props.colors.background_color }}></div>
                                        <div className="col-6 nopadding">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div
                                                >
                                                    <img className="logo" src={props.organisation.logos[props.colors.template_logo_nr]} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6 col-right">
                            <div >
                                <div className="house2 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}></div>
                            </div>
                            <div >
                                <div className="house3 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }}></div>
                            </div>
                            <div >
                                <div className="house4 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[0].media[3] + ')' }}></div>
                            </div>
                            <div >
                                <div className="house5 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[0].media[4] + ')' }}></div>
                            </div>
                            <div className="details"
                                style={{ backgroundColor: props.colors.secondary_color, color: props.colors.primary_text_color }}>
                                <div className="row">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-8 js-ka-k">{getTemplateData(0, 0)[0]}</div>
                                            <div className="col-4 text-right js-ka-a">{getTemplateData(0, 0)[1]}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-8 js-ka-k">{getTemplateData(0, 1)[0]}</div>
                                            <div className="col-4 text-right js-ka-a">{getTemplateData(0, 1)[1]}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-8 js-ka-k">{getTemplateData(0, 2)[0]}</div>
                                            <div className="col-4 text-right js-ka-a">{getTemplateData(0, 2)[1]}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-8 js-ka-k">{getTemplateData(0, 3)[0]}</div>
                                            <div className="col-4 text-right js-ka-a">{getTemplateData(0, 3)[1]}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-8 js-ka-k">{getTemplateData(0, 3)[0]}</div>
                                            <div className="col-4 text-right js-ka-a">{getTemplateData(0, 3)[1]}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-8 js-ka-k">{getTemplateData(0, 4)[0]}</div>
                                            <div className="col-4 text-right js-ka-a">{getTemplateData(0, 4)[1]}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="gmaps-block d-flex justify-content-center align-items-center" >
                                <img className="gmaps" src={houseData[0].map} alt="" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
        </div>
    );
}







