export const selectOrganisation = state => {
    return {
        type: "ORGANISATION_SELECTED",
        payload: state
    };
};
export const selectOrientations = state => {
    return {
        type: "ORIENTATION_SELECTED",
        payload: state
    };
};
export const selectStyling = state => {
    return {
        type: "STYLING_SELECTED",
        payload: state
    };
};
export const selectCustomColors = state => {
    return {
        type: "CUSTOM_COLORS_SELECTED",
        payload: state
    };
};
export const askTemplatesActivation = state => {
    return {
        type: "ASKED_TEMPLATE_ACTIVATION_SELECTED",
        payload: state
    };
};