
import React, { useEffect, useState } from 'react'

import './assets/fonts/fonts.css'
import './index.css';

import ShowPage from './pages/show-page/showpage.js';
import axios from 'axios';
import EditPage from './pages/edit-page/edit-page';
import EditTemplatePage from './pages/edit-template-page/edit-template-page';

import { connect } from "react-redux";

import { selectOrientations } from "./store/actions";
import { selectOrganisation } from "./store/actions";
import { selectStyling } from "./store/actions";
import {selectCustomColors} from "./store/actions";
import {askTemplatesActivation} from "./store/actions";

import Topbar1 from "./pages/global-elements/topbar1.js"
import Bottombar from './pages/global-elements/bottombar';
import { bindActionCreators } from "redux";

function App(props) {
    const url_prefix = window.location.pathname;
    const [isDataLoaded, setIsDataLoaded] = useState("");

    function getOrganisationName(){
        if(props.page === "ShowPage")  return url_prefix;
        else if(props.page === "EditPage")  return url_prefix.split("/")[1];
        else if(props.page === "EditTemplatePage") return url_prefix.split("/")[1];
    }
    
    useEffect(() => {   
        const organisation = getOrganisationName();

        if(props.selectedOrganisation.name === ""){

            // var organisation = window.location.href.split("/").pop();
            // organisation = organisation.replace(/\s+/g, '_').toLocaleLowerCase();
            
            axios.get(`https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/organisations/${organisation}.json`)
            .then((response) => {
                // console.log(response.data)
                props.selectOrganisation(response.data.organisation);
                props.selectOrientations({
                    saved_orientation: response.data.saved_orientation,
                    local_orientation: response.data.saved_orientation
                });
                props.selectStyling(response.data.styling);
                props.askTemplatesActivation({
                    saved:response.data.activated_template,
                    local: ""
                });
                // @#@ systeem dat er bij teweinig logos de overige worden aangevuld
                if(response.data.custom_template_colors) props.selectCustomColors(response.data.custom_template_colors);
                setIsDataLoaded(true)
                document.title = 'Demo Viewie - '+response.data.organisation.name;
            }).catch(error => {
                console.log(error)
                setIsDataLoaded(false)
            });
        }
    });



    function getPage(){
        switch (props.page) {
            // case "ShowPage":
                // return <ShowPage></ShowPage>;
            case "EditPage":
                return <EditPage></EditPage>
            case "EditTemplatePage":
                return <EditTemplatePage organisation_url={getOrganisationName()}></EditTemplatePage>
            default:
                return;
        }
    }
    
    if(isDataLoaded){
        return (
            <div className="App">  
                <Topbar1></Topbar1>
                <div className="thinner">
                    {getPage()}
                  
                    {/* This way the showPage doesnt have to re-render every time you go to another page, 
                    This could take up to 500ms */}
                    <ShowPage page={props.page}></ShowPage>
                </div>
                <Bottombar></Bottombar>
            </div>
        );
    }
    if(isDataLoaded === false){
        return (
            <div className="App" >  
            <Topbar1></Topbar1>
            <div style={{textAlign: 'center', marginTop: '10px'}}>Data laden is helaas mislukt</div>
            </div>
        );
    }
    return (
        <div className="App" >  
            <Topbar1></Topbar1>
            <div style={{textAlign: 'center', marginTop: '10px'}}>Data is aan het laden</div>
        </div>
    );
}


// Als eerste routing regelen

function mapStateToProps(state) {
    return {
        selectedOrganisation: state.selectedOrganisation
    }
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
        selectOrientations: selectOrientations,
        selectOrganisation: selectOrganisation,
        selectStyling: selectStyling,
        selectCustomColors: selectCustomColors,
        askTemplatesActivation: askTemplatesActivation,
    },
    dispatch
  );
}
export default connect(mapStateToProps,matchDispatchToProps)(App);