import React, {useState} from "react";
import './topbottombar.css';
import emailjs from '@emailjs/browser';


function BottomBarRed(props) {
    const [mustCustomShow, setMustCustomShow] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        if(!(e.target.querySelector("input[name='person_name']").value && e.target.querySelector("textarea[name='message']").value) ){
            return alert("Vul alstublieft eerst uw naam en bereikbaarheid in")
        }
        
        document.querySelector('#askCustomTemplateDiv form').style.pointerEvents = "none";
        document.querySelector('#askCustomTemplateDiv form button.button').style.opacity = "0.5";
        document.querySelector('#askCustomTemplateDiv form textarea').style.opacity = "0.5";
        document.querySelector('#askCustomTemplateDiv form button.button').innerHTML =  "Wordt verwerkt";

        emailjs.sendForm('service_ww6dt2m', 'maatwerk_template', e.target, 'AL8yYUGFAEbnXLJme')
            .then((result) => {
                alert("Uw bericht is succesvol verstuurd naar Viewie");
                setMustCustomShow(false)
            }, (error) => {
                console.log(error.text);
                alert("Bericht sturen mislukt");
                setMustCustomShow(false)

            });
    };
    // https://stackoverflow.com/questions/6714202/how-can-i-disable-the-enter-key-on-my-textarea

    return (
        <div className="BottomBarRed">
            Unieke wensen? Benieuwd naar de mogelijkheden van een maatwerk template?
            <div className="style" onClick={() => setMustCustomShow(true)}>Maatwerk template</div>
            {mustCustomShow ?
            <div id="askCustomTemplateDiv" >
            
                <form className="invisibleForm" onSubmit={sendEmail}>
                    <div className="times" onClick={() => setMustCustomShow(false)} style={{ 'WebkitMask': "url(" + require("../../assets/icons/times.svg") + ") no-repeat center" }}></div>
                    <div className="textAboveForm">
                        <div><b>Bericht aan Viewie over Maatwerk</b></div>
                        <div>'Hallo,
                             Ik zou graag meer willen weten over het Viewie maatwerk product.'
                        </div>
                        <div className="mt-15">'Mijn naam is als volgt:'</div>
                        <input type="text" placeholder="Naam" name="person_name" />
                        <div className="mt-15">'En ik ben op de volgende manieren te bereiken:'</div>
                    </div>
                    <input type="text" defaultValue={props.selectedOrganisation.name} name="name" readOnly />
                    <input type="text" defaultValue={props.selectedOrganisation.location} name="location" readOnly />
                    <textarea name="message" id="js_message" placeholder="Bv. Mail / Telefoon / Tijden" maxLength={500}></textarea>
                    <div className="textBelowForm">
                        {/* <div>Met vriendelijke groet,</div> */}
                        <div>-{props.selectedOrganisation.name}, {props.selectedOrganisation.location}</div>
                    </div>
                    <button className="button">Aanvragen maatwerk</button>
                </form>
                <div className="blackBlock" onClick={() => setMustCustomShow(false)}></div>
            </div>
            : null }
        </div>
    );
}
// https://stackoverflow.com/questions/39848979/outlook-email-client-pointer-eventsnone

export default BottomBarRed;