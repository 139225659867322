import { createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import allReducers from "./reducers";

const initialState = {
    selectedOrganisation: {
        name: "",
        location: "",
        logos: ["","",""],
        url_name: "",
    },

    selectedOrientations: {
        saved_orientation: "landscape",
        local_orientation: "landscape",
    },
    
    selectedStyling: {
        // template_logo_nr: "0",
        primary_color: "#fff",
        secondary_color: "#fff",
        tertiary_color: "#fff",
        primary_text_color: "#000",
        secondary_text_color: "#000",
        tertiary_text_color: "#000",

        // label_color: "#fff",
        // label_text_color: "#000",
        background_color: "#ededed",
        font: "Open Sans",
        // progressbar_color: "",
        // qr_code_color: "",        

        label_new_color: "#fff",
        label_new_text_color: "#000",
        label_forsale_color: "#fff",
        label_forsale_text_color: "#000",
        label_under_offer_color: "#fff",
        label_under_offer_text_color: "#000",
        label_sold_conditional_color: "#fff",
        label_sold_conditional_text_color: "#000",
        label_sold_color: "#fff",
        label_sold_text_color: "#000",
    },
    selectedCustomColors:{},
    activated_templates: {
        saved: "",
        local: ""
    }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  allReducers,
  initialState,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);
// const store = createStore(allReducers, initialState);
export default store;