import React from "react";
import './vmt09.css';
import { getTemplateData, houseData, getStatusColors } from '../../../global-elements/globals.js'

export default function Vmt09(props) {
return (
    <div id="vmt-09" className ="slide rotation">
        <div className="container p-0" style={{ backgroundColor: props.colors.background_color }}>
       
            <div className="topbar" style={{ backgroundColor: props.colors.primary_color }}  >
               <div className="status" style={getStatusColors(props.colors, houseData[0].status)}>
                    <span>{houseData[0].status}</span>
                </div>
            </div>

            <div className="img-container">
                <div className="address-details" style={{ color: props.colors.secondary_text_color }} >
                    {houseData[0].address},&nbsp;
                    { props.organisation.location}
                </div>
                <div className="shrink-animation house0 bg-img"
                    style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }} ></div >

                <div className="bottom-pictures" >
                    <div className="s-img s-img-left" style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}></div >
                    <div className="s-img s-img-right" style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }} ></div >
                </div >
            </div>

            <div className="boxTable" style={{ color: props.colors.primary_text_color }}>{/* Was originally secondary_text_color */}
                <div className="boxTableItem">
                    <span className="js-ka-k">{getTemplateData(0, 0)[0]}</span>
                    <span className="js-ka-a">{getTemplateData(0, 0)[1]}</span>
                </div>
                <div className="boxTableItem">
                    <span className="js-ka-k">{getTemplateData(0, 1)[0]}</span>
                    <span className="js-ka-a">{getTemplateData(0, 1)[1]}</span>
                </div>
                 <div className="boxTableItem">
                    <span className="js-ka-k">{getTemplateData(0, 2)[0]}</span>
                    <span className="js-ka-a">{getTemplateData(0, 2)[1]}</span>
                </div>
                 <div className="boxTableItem">
                    <span className="js-ka-k">{getTemplateData(0, 3)[0]}</span>
                    <span className="js-ka-a">{getTemplateData(0, 3)[1]}</span>
                </div>
                 <div className="boxTableItem">
                    <span className="js-ka-k">{getTemplateData(0, 4)[0]}</span>
                    <span className="js-ka-a">{getTemplateData(0, 4)[1]}</span>
                </div>
                 <div className="boxTableItem">
                    <span className="js-ka-k">{getTemplateData(0, 5)[0]}</span>
                    <span className="js-ka-a">{getTemplateData(0, 5)[1]}</span>
                </div>
            </div >

            <div className="bottombar" style={{ backgroundColor: props.colors.secondary_color }} >
                <div className="logo">
                    <img  src={props.organisation.logos[props.colors.template_logo_nr]} alt="" />
                </div >
                <div className="price-container" style={{ backgroundColor: props.colors.tertiary_color, color: props.colors.tertiary_text_color }}>
                    <span className = "ask-price"> Vraagprijs:  {/* @#@ vraagprijs */}</span >
                    <span >{houseData[0].price}</span >
                </div >
            </div>

    
        </div>
        <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
    </div>
)
}