import React, { useState } from 'react';
import Topbar from "../global-elements/topbar.js";
// import './form.css';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import './showpage.css';
import BottomBarRed from "../global-elements/bottombar_red.js"

import Vmts00 from './templates/vmts00/vmts00.js';
import Vmts01 from './templates/vmts01/vmts01.js';
import Vmts02 from './templates/vmts02/vmts02.js';
import Vmts03 from './templates/vmts03/vmts03.js';
import Vmts04 from './templates/vmts04/vmts04.js';
import emailjs from '@emailjs/browser';

import Vmt00 from './templates/vmt00/vmt00.js';
import Vmt01 from './templates/vmt01/vmt01.js';
import Vmt02 from './templates/vmt02/vmt02.js';
import Vmt03 from './templates/vmt03/vmt03.js';
import Vmt04 from './templates/vmt04/vmt04.js';
import Vmt05 from './templates/vmt05/vmt05.js';
import Vmt10 from './templates/vmt10/vmt10.js';
import Vmt09 from './templates/vmt09/vmt09.js';
import Vmt13 from './templates/vmt13/vmt13.js';
import Vmt15 from './templates/vmt15/vmt15.js';
import Vmt49 from './templates/vmt49/vmt49.js';
import { askTemplatesActivation } from "../../store/actions";
import { bindActionCreators } from "redux";
import { getDefaultLogo } from '../global-elements/globals.js';
import axios from 'axios';

function ShowPage(props) {
    const [selectedTemplate, setSelectedTemplate] = useState(undefined);
    const [activatedTemplate, setActivatedTemplate] = useState(["", ""]);

    const sendActivatedTemplate = (e) => {
        e.preventDefault();

        document.querySelector('form.invisibleForm button').style.pointerEvents = "none";
        document.querySelector('form.invisibleForm button').style.opacity = "0.5";
        document.querySelector('form.invisibleForm button').innerHTML = "Wordt verwerkt";
        
        emailjs.sendForm('service_ww6dt2m', 'activate_demo_template', e.target, 'AL8yYUGFAEbnXLJme')
            .then((result) => {
                alert("Uw bericht is succesvol verstuurd naar Viewie");

                props.askTemplatesActivation({...props.activated_templates, local: e.target[0].value})

                setActivatedTemplate(["", ""])
            }, (error) => {
                alert("Bericht sturen mislukt");
            },

            axios.patch(`https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/organisations/${props.selectedOrganisation.url_name}.json`, {
                 activated_template: e.target[0].value
            }).then(function (res) {
            }).catch(function (error) {
                 alert("Aanpassingen opslaan mislukt")
                 console.log(error.response);
            })


        );
    };


    document.onkeydown = GetDirectionFromKey;
    function GetDirectionFromKey(e) {
        if (selectedTemplate !== undefined) {
            if (e.keyCode === 37
            ) { //Links
                slide("left");
            }
            else if (e.keyCode === 39) {
                slide("right");
            }
            else if (e.keyCode === 8) {
                setSelectedTemplate(undefined)
            }
        }
    }

    function slide(direction) {
        if (direction === "left" && selectedTemplate > 0) { //Links
            setSelectedTemplate((selectedTemplate - 1).toString())
        }
        else if (direction === "right" && selectedTemplate < (templateArray.length - 1)) {
            setSelectedTemplate((parseInt(selectedTemplate) + 1).toString())
        }
    }

    function getColors(template) {
        let futureLocalStyling = JSON.parse(JSON.stringify(props.selectedStyling));
        if (props.selectedCustomColors[template]) {
            futureLocalStyling.template_logo_nr = props.selectedCustomColors[template].template_logo_nr;
            futureLocalStyling.primary_color = props.selectedCustomColors[template].primary_color;
            futureLocalStyling.secondary_color = props.selectedCustomColors[template].secondary_color;
            futureLocalStyling.tertiary_color = props.selectedCustomColors[template].tertiary_color;
            futureLocalStyling.primary_text_color = props.selectedCustomColors[template].primary_text_color;
            futureLocalStyling.secondary_text_color = props.selectedCustomColors[template].secondary_text_color;
            futureLocalStyling.tertiary_text_color = props.selectedCustomColors[template].tertiary_text_color;
            return futureLocalStyling;
        }
        futureLocalStyling.template_logo_nr = getDefaultLogo(template);
        return futureLocalStyling;
    }
    function checkIfCustom(i, template) {

        if (props.selectedCustomColors[template]) {
            return "Voorstel " + i + " (Los)";
        }
        return "Voorstel " + i;
    }
    // function getColorsFromDisconnectedTemplate(template){
    //     if(listOfDisconnectedTemplates.includes(template)){
    //         return {
    //             primary_color: "red"
    //         }
    //     }
    //     return false;
    // }

    const templateArray = [
        [<Vmts00 colors={getColors("vmts00")} organisation={props.selectedOrganisation}></Vmts00>, "vmts00"],
        [<Vmts01 colors={getColors("vmts01")} organisation={props.selectedOrganisation}></Vmts01>, "vmts01"],
        [<Vmts02 colors={getColors("vmts02")} organisation={props.selectedOrganisation}></Vmts02>, "vmts02"],
        [<Vmts03 colors={getColors("vmts03")} organisation={props.selectedOrganisation}></Vmts03>, "vmts03"],
        [<Vmts04 colors={getColors("vmts04")} organisation={props.selectedOrganisation}></Vmts04>, "vmts04"],
        
        [<Vmt00 colors={getColors("vmt00")} organisation={props.selectedOrganisation}></Vmt00>, "vmt00"],
        [<Vmt01 colors={getColors("vmt01")} organisation={props.selectedOrganisation}></Vmt01>, "vmt01"],
        [<Vmt05 colors={getColors("vmt05")} organisation={props.selectedOrganisation}></Vmt05>, "vmt05"],
        [<Vmt09 colors={getColors("vmt09")} organisation={props.selectedOrganisation}></Vmt09>, "vmt09"],
        [<Vmt10 colors={getColors("vmt10")} organisation={props.selectedOrganisation}></Vmt10>, "vmt10"],
        [<Vmt13 colors={getColors("vmt13")} organisation={props.selectedOrganisation}></Vmt13>, "vmt13"],
        [<Vmt15 colors={getColors("vmt15")} organisation={props.selectedOrganisation}></Vmt15>, "vmt15"],
        [<Vmt49 colors={getColors("vmt49")} organisation={props.selectedOrganisation}></Vmt49>, "vmt49"],

        [<Vmt02 colors={getColors("vmt02")} organisation={props.selectedOrganisation}></Vmt02>, "vmt02"],
        [<Vmt03 colors={getColors("vmt03")} organisation={props.selectedOrganisation}></Vmt03>, "vmt03"],
        [<Vmt04 colors={getColors("vmt04")} organisation={props.selectedOrganisation}></Vmt04>, "vmt04"],
    ];


    // @#@To Turn scrolling off use:
    // document.body.style.overflowY = "hidden";
    // document.body.style.overflowY = "auto";

    return (
        <div className="ShowPage" style={props.page === "ShowPage" ? null : { position: 'fixed', top: '9999px'}}>
            <Topbar organisation={props.selectedOrganisation} orientation={props.selectOrientation} page="show"></Topbar>
            {/* Hierboven met orientation */}
            <div className={props.selectedOrientations.local_orientation === "landscape" ? "templatesGrid landscape" : "templatesGrid portrait"} >
                {templateArray.map((template, index) => (
                    <div className='gridItemHeight' key={index}  >
                        <span className="title">{checkIfCustom(index + 1, template[1])}</span>
                        <div onClick={() => setSelectedTemplate(index.toString())} style={{ fontFamily: props.selectedStyling.font }}>
                            {template[0]}
                        </div>
                        <span className='buttons'>
                            <Link to={"/" + props.selectedOrganisation.url_name + "/" + template[1]}>
                                <div className="button"><div>Bewerk</div></div>
                            </Link>
                            {
                                props.activated_templates.saved === template[1] || props.activated_templates.local === template[1]
                                    ?
                                        props.activated_templates.saved === template[1] ?
                                                <div className="button asked">Geactiveerd</div>
                                            :
                                                <div className="button asked">Aangevraagd</div>
                                    :
                                    <div className="button" onClick={() => setActivatedTemplate([template[1], "Voorstel " + (index + 1)])}>Activeren</div>
                            }
                       
                        </span>
                    </div>
                ))}
            </div>

            {selectedTemplate ?
                <div id='selectedTemplate' style={{ fontFamily: props.selectedStyling.font }} className={props.selectedOrientations.local_orientation === "landscape" ? "landscape" : "portrait"}>
                    <div className='blackBlock' onClick={() => setSelectedTemplate(undefined)}></div>
                    <div className='center'>

                        <span className="title">Voorstel {parseInt(selectedTemplate) + 1}</span>
                        <div className="times" onClick={() => setSelectedTemplate(undefined)} style={{ 'WebkitMask': "url(" + require("../../assets/icons/times.svg") + ") no-repeat center" }}></div>
                        {templateArray[selectedTemplate][0]}
                        {selectedTemplate > 0 ? <img className="chevron-left" alt="" onClick={() => slide("left")} style={{ 'WebkitMask': "url(" + require("../../assets/icons/chevron-left.svg") + ") no-repeat center" }} /> : null}
                        {selectedTemplate < (templateArray.length - 1) ? <img className="chevron-right" alt="" onClick={() => slide("right")} style={{ 'WebkitMask': "url(" + require("../../assets/icons/chevron-right.svg") + ") no-repeat center" }} /> : null}
                        <span className="underNumberOf">{parseInt(selectedTemplate) + 1 + "/" + templateArray.length}   </span>

                        <span className='buttons'>
                            <Link to={"/" + props.selectedOrganisation.url_name + "/" + templateArray[selectedTemplate][1]} onClick={()=>setSelectedTemplate(undefined)}>
                                <div className="button"><div>Bewerk</div></div>
                            </Link>
                            {props.activated_templates.saved === templateArray[selectedTemplate][1] || props.activated_templates.local === templateArray[selectedTemplate][1] ? 
                                props.activated_templates.saved === templateArray[selectedTemplate][1] ?
                                    <div className="button asked">Geactiveerd</div>
                                        :
                                    <div className="button asked">Aangevraagd</div> 
                                : 
                            <div className="button" onClick={() => [setSelectedTemplate(undefined), setActivatedTemplate([templateArray[selectedTemplate][1], "Voorstel " + (parseInt(selectedTemplate) + 1)])]}>Activeren</div>}
                        </span>
                    </div>

                </div>
                : null}

            {activatedTemplate[0] ?
                <>
                    <form className="invisibleForm" onSubmit={sendActivatedTemplate}>
                        <div className="times" onClick={() => setActivatedTemplate([null, null])} style={{ 'WebkitMask': "url(" + require("../../assets/icons/times.svg") + ") no-repeat center" }}></div>

                        <div className="textAboveForm">
                            <div><b>Activeer template {activatedTemplate[1]} ({activatedTemplate[0]})</b></div>
                            <div>{props.selectedOrganisation.name}, {props.selectedOrganisation.location}</div>
                        </div>
                        <input type="text" defaultValue={activatedTemplate[0]} name="template" readOnly />
                        <input type="text" defaultValue={activatedTemplate[1]} name="voorstelNr" readOnly />
                        <input type="text" defaultValue={props.selectedOrganisation.name} name="name" readOnly />
                        <input type="text" defaultValue={props.selectedOrganisation.location} name="location" readOnly />
                        <input type="text" defaultValue={props.selectedOrganisation.url_name} name="url_name" readOnly />
                        <button className="button">Activeer {activatedTemplate[1]} </button>
                    </form>

                    <div className="blackBlock" onClick={() => setActivatedTemplate([null, null])}></div>
                </>
                : null}

            <BottomBarRed selectedOrganisation={props.selectedOrganisation}></BottomBarRed>
        </div>
    );
}


function mapStateToProps(state) {
    return {
        selectedOrientations: state.selectedOrientations,
        selectedOrganisation: state.selectedOrganisation,
        selectedStyling: state.selectedStyling,
        selectedCustomColors: state.selectedCustomColors,
        activated_templates: state.activated_templates
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            askTemplatesActivation: askTemplatesActivation,
        },
        dispatch
    );
}
export default connect(mapStateToProps, matchDispatchToProps)(ShowPage);
// @#@kleine huizenafbeeldingen voor sneller laden