import { combineReducers } from "redux";
import SelectedOrganisationReducer from "./reducer-selected-organisation";
import selectedOrientationsReducer from "./reducer-selected-orientation";
import selectedStylingReducer from "./reducer-selected-styling";
import selectedCustomColorsReducer from "./reducer-selected-custom-colors";
import activated_templatesReducer from "./reducer-asked-templates-activation";

const allReducers = combineReducers({
  //ROEPT ALLES AAN
  // viewStates: ViewStateReducer,
  selectedOrganisation: SelectedOrganisationReducer,
  selectedOrientations: selectedOrientationsReducer,
  selectedStyling: selectedStylingReducer,
  selectedCustomColors: selectedCustomColorsReducer,
  activated_templates: activated_templatesReducer,
});

export default allReducers;