import React, { useState } from "react";
// import React from "react";

import Topbar from "../global-elements/topbar.js"

import './edit-page.css';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectStyling, selectOrientations } from "../../store/actions";
import { possibleFontsArray } from '../global-elements/globals.js'
import ColorSelector from '../global-elements/color-selector/color-selector.js';

function EditPage(props) {
    const [hasDataChanged, setHasDataChanged] = useState(false);
    const [futureStyling, setFutureStyling] = useState({
        // template_logo_nr: props.selectedStyling.template_logo_nr,
        primary_color: props.selectedStyling.primary_color,
        secondary_color: props.selectedStyling.secondary_color,
        tertiary_color: props.selectedStyling.tertiary_color,

        primary_text_color: props.selectedStyling.primary_text_color,
        secondary_text_color: props.selectedStyling.secondary_text_color,
        tertiary_text_color: props.selectedStyling.tertiary_text_color,

        background_color: props.selectedStyling.background_color,
        font: props.selectedStyling.font,
        label_forsale_color: props.selectedStyling.label_forsale_color,
        label_forsale_text_color: props.selectedStyling.label_forsale_text_color,
        label_sold_color: props.selectedStyling.label_sold_color,
        label_sold_text_color: props.selectedStyling.label_sold_text_color
    });
    const updateStylingFieldFunction = (valueName, newValue) => {
        setFutureStyling({ ...futureStyling, [valueName]: newValue })
    }

    function handleSubmit(hasPreferedOrientationChanged = false) {
        if (!hasDataChanged && !hasPreferedOrientationChanged) return;
        // Localsave
        props.selectStyling(futureStyling);
        props.selectOrientations({
            saved_orientation: props.selectedOrientations.local_orientation,
            local_orientation: props.selectedOrientations.local_orientation
        });
        // For online save
        axios.patch(`https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/organisations/${props.selectedOrganisation.url_name}.json`, {
            styling: futureStyling,
            saved_orientation: props.selectedOrientations.local_orientation,
        }).then(function (res) {
            // successful response flow
            // For direct local changes

            //    console.log(res)
        }).catch(function (error) {
            // error response flow
            alert("Aanpassingen opslaan mislukt")
            console.log(error.response);
        });
    }

    const getPossibleFontsArray = possibleFontsArray.map((font, i) =>
        <option style={{ fontFamily: font }} key={i} value={font}>
            {font}
        </option>
    );

    return (
        <div className="EditPage">
            <Topbar page="edit" hasDataChanged={hasDataChanged} submitFunction={handleSubmit}></Topbar>
            <form onChange={() => setHasDataChanged(true)} className="form" id="js-edit-form">
                <div className="form-block pt-65">
                    <div className="inner-form-block">
                        <div className="styling-title">Custom styling
                            <div className="styling-undertitle">Deze elementen kunt u ook nog los selecteren per template</div>
                        </div>
                        <ColorSelector id="primary_color" name="Primaire kleur" value={futureStyling.primary_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                        <ColorSelector id="primary_text_color" name="Primaire tekst kleur" value={futureStyling.primary_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                        <ColorSelector id="secondary_color" name="Secondaire kleur" value={futureStyling.secondary_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                        <ColorSelector id="secondary_text_color" name="Secondaire tekst kleur" value={futureStyling.secondary_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                        <ColorSelector id="tertiary_color" name="Tertiare kleur" value={futureStyling.tertiary_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                        <ColorSelector id="tertiary_text_color" name="Tertiare tekst kleur" value={futureStyling.tertiary_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                        {/* 
                    <select name="template_logo_nr" id="template_logo_nr" defaultValue={futureStyling.template_logo_nr} onChange={e=> setFutureStyling({ ...futureStyling, 'template_logo_nr': e.target.value })}> 
                        <option value="0">Standaard logo: 1</option>
                        {props.selectedOrganisation.logos.length > 1 ? <option value="1">Standaard logo: 2</option> : null}
                        {props.selectedOrganisation.logos.length > 2 ? <option value="2">Standaard logo: 3</option> : null}
                    </select> */}
                    </div>
                </div>
                <div className="form-block">
                    <div className="inner-form-block">
                        <div className="styling-title">Overige styling</div>
                        <ColorSelector id="background_color" name="Achtergrond kleur" value={futureStyling.background_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                        <div className="selectContainer">
                            <select name="font" id="font" onChange={e => updateStylingFieldFunction('font', e.target.value)} defaultValue={props.selectedStyling.font} >
                                {getPossibleFontsArray}
                            </select>
                            <div style={{ opacity: 0.3, fontSize: '11px' }}>Voor andere lettertypes kunt u contact met ons opnemen</div>
                        </div>
                    </div>
                </div>

                <div className="form-block">
                    <div className="inner-form-block">
                        <div className="styling-title">Kleuren status</div>
                        <ColorSelector id="label_forsale_color" name="'Te koop' achtergrond" value={futureStyling.label_forsale_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                        <ColorSelector id="label_forsale_text_color" name="'Te koop' tekst" value={futureStyling.label_forsale_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                        <ColorSelector id="label_sold_color" name="'Verkocht' achtergrond" value={futureStyling.label_sold_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                        <ColorSelector id="label_sold_text_color" name="'Verkocht' tekst" value={futureStyling.label_sold_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                    </div>
                </div>
                {/* <div className="button submit" onClick={()=>handleSubmit()}>Aanpassingen opslaan</div> */}
            </form>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        selectedOrientations: state.selectedOrientations,
        selectedOrganisation: state.selectedOrganisation,
        selectedStyling: state.selectedStyling,
        selectedCustomColors: state.selectedCustomColors,
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            selectStyling: selectStyling,
            selectOrientations: selectOrientations
        },
        dispatch
    );
}
export default connect(mapStateToProps, matchDispatchToProps)(EditPage);