




import React from "react";
import './vmt02.css';
import { getTemplateData, houseData, getStatusColors } from '../../../../pages/global-elements/globals.js'

export default function Vmt02(props) {

    return (
        <div id="vmt-02" className="slide rotation">
            <div className="container" style={{ backgroundColor: props.colors.background_color }}>
                <div className="row">
                    <div className="col nopadding">
                        <div className="header-top" style={{ backgroundColor: props.colors.secondary_color }}></div>
                        <div className="header-block">
                            <div className="header-atback" style={{ backgroundColor: props.colors.tertiary_color }}>
                            </div>
                            <div className="logo">
                                <div>
                                    <img src={props.organisation.logos[props.colors.template_logo_nr]} alt="logo" />
                                </div>
                            </div>
                            <div className="logo-wrap">
                                <div className="svg-wrap">
                                </div>
                            </div>
                            <div className="header" ></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 nopadding">

                        <div className="firsthouse">
                            <div className="row">
                                <div className="col">
                                    <div className="address-details text-left"
                                        style={{ color: props.colors.primary_text_color }}>
                                        <div >
                                            <span className="street">{houseData[0].address}, </span>
                                            <span className="city">{props.organisation.location}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-pt-8">
                                    <div className="status" style={getStatusColors(props.colors, houseData[0].status)}>
                                        <span>{houseData[0].status}</span>
                                    </div>

                                    <div className="overflow">
                                        <div
                                        >
                                            <div className="shrink-animation house1 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-pt-4">
                                    <div className="row">
                                        <div className="col gallery">
                                            <div className="house-block">
                                                <div >
                                                    <div className="house2 bg-img"
                                                        style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="house-block">
                                                <div >
                                                    <div className="house3 bg-img"
                                                        style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="house-block">
                                                <div>
                                                    <div className="house4 bg-img"
                                                        style={{ backgroundImage: 'url(' + houseData[0].media[3] + ')' }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row object-pice-block m-0"
                                style={{ backgroundColor: props.colors.primary_color, color: props.colors.secondary_text_color }}>
                                <div className="col">
                                    <div className="details">

                                        <div>
                                            <div className="row">
                                                <div className="col-8 js-ka-k">{getTemplateData(0, 0)[0]}</div>
                                                <div className="col-4 text-right js-ka-a">{getTemplateData(0, 0)[1]}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-8 js-ka-k">{getTemplateData(0, 1)[0]}</div>
                                                <div className="col-4 text-right js-ka-a">{getTemplateData(0, 1)[1]}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-8 js-ka-k">{getTemplateData(0, 2)[0]}</div>
                                                <div className="col-4 text-right js-ka-a">{getTemplateData(0, 2)[1]}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-8 js-ka-k">{getTemplateData(0, 3)[0]}</div>
                                                <div className="col-4 text-right js-ka-a">{getTemplateData(0, 3)[1]}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-8 js-ka-k">{getTemplateData(0, 4)[0]}</div>
                                                <div className="col-4 text-right js-ka-a">{getTemplateData(0, 4)[1]}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="cborder" style={{ backgroundColor: props.colors.secondary_color }}>
                                </div>
                                <div className="col">
                                    <div
                                    >
                                        <div className="price-block d-flex justify-content-center align-content-end flex-column">
                                            {/* <div         className="ask-price text-right"
                                        style={{ color: props.colors.primary_text_color}}>
                                        {{ slide.data[0].sale_type == '2' ? 'Huurprijs:' : 'Vraagprijs:' }}</div>@#@ */}
                                            <div className="ask-price text-right">Vraagprijs</div>
                                            <div className="price d-inline-block text-right"
                                                >
                                                <div>
                                                    {houseData[0].price}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-6 nopadding">

                        <div className="secondhouse">
                            <div className="row">
                                <div className="col">
                                    <div className="address-details text-right"
                                        style={{ color: props.colors.primary_text_color }}>

                                        <span className="street text-color-dark">{houseData[1].address}, </span>




                                        <span className="city text-color-dark">{props.organisation.location}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-pt-8">
                                    <div className="status" style={getStatusColors(props.colors, houseData[1].status)}>
                                        <span>{houseData[1].status}</span>
                                    </div>
                                    <div className="overflow">
                                        <div
                                        >
                                            <div className="shrink-animation house1 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[1].media[0] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-pt-4">
                                    <div className="row">
                                        <div className="col gallery">
                                            <div className="house-block">
                                                <div>
                                                    <div className="house2 bg-img"
                                                        style={{ backgroundImage: 'url(' + houseData[1].media[1] + ')' }}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="house-block">
                                                <div>
                                                    <div className="house3 bg-img"
                                                        style={{ backgroundImage: 'url(' + houseData[1].media[2] + ')' }}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="house-block">
                                                <div>
                                                    <div className="house4 bg-img"
                                                        style={{ backgroundImage: 'url(' + houseData[1].media[3] + ')' }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row object-pice-block m-0"
                                style={{ backgroundColor: props.colors.primary_color, color: props.colors.secondary_text_color }}>
                                <div className="col">
                                    <div className="details">



                                        <div>
                                            <div className="row">
                                                <div className="col-8 js-ka-k">{getTemplateData(1, 0)[0]}</div>
                                                <div className="col-4 text-right js-ka-a">{getTemplateData(1, 0)[1]}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-8 js-ka-k">{getTemplateData(1, 1)[0]}</div>
                                                <div className="col-4 text-right js-ka-a">{getTemplateData(1, 1)[1]}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-8 js-ka-k">{getTemplateData(1, 2)[0]}</div>
                                                <div className="col-4 text-right js-ka-a">{getTemplateData(1, 2)[1]}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-8 js-ka-k">{getTemplateData(1, 3)[0]}</div>
                                                <div className="col-4 text-right js-ka-a">{getTemplateData(1, 3)[1]}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-8 js-ka-k">{getTemplateData(1, 4)[0]}</div>
                                                <div className="col-4 text-right js-ka-a">{getTemplateData(1, 4)[1]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cborder" style={{ backgroundColor: props.colors.secondary_color }}>
                                </div>
                                <div className="col">
                                    <div
                                    >
                                        <div className="price-block d-flex justify-content-center align-content-end flex-column">
                                            {/* <div         className="ask-price text-right"
                                        style={{ color: props.colors.primary_text_color}}>
                                        {{ slide.data[1].sale_type == '2' ? 'Huurprijs:' : 'Vraagprijs:' }}</div>  @#@*/}
                                            <div className="ask-price text-right">Vraagprijs</div>
                                            <div className="price d-inline-block text-right" >
                                                <div>
                                                    {houseData[0].price}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col nopadding">
                        <div className="bottom-footer" style={{ backgroundColor: props.colors.secondary_color }}>
                        </div>
                    </div>
                </div>


            </div>
            <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
        </div>
    );
}







