import React from "react";
import './topbottombar.css';
import { connect } from "react-redux";
import { selectOrientations } from "../../store/actions";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {possibleTemplatesInOrder} from "../global-elements/globals.js"

function Topbar(props) {

    function getVoorstelFromTemplateName(){
        if(!props.templateName) return "";
   
        for(let i = 0; i< possibleTemplatesInOrder.length; i++){
            // console.log(templateName +" "+possibleTemplates[i])
            if(possibleTemplatesInOrder[i] === props.templateName) return " (Voorstel "+(i+1)+")";
        }
    }
    


    function isNewOrientationDifferent(){
        if(props.selectedOrientations.saved_orientation !== props.selectedOrientations.local_orientation){
            return true;
        }
        return false;
    }

    function changeOrientationTo(newOrientation){
        props.selectOrientations({
            saved_orientation: props.selectedOrientations.saved_orientation,
            local_orientation: newOrientation
        })
    }
    function getPageFunction(){
        if(props.page === "show"){
            return "Woning templates voorstellen"
        }
        else if(props.page === "edit"){
            return "Huisstijl aanpassen"
        }
        else if(props.page === "edit-template"){
            return "Template eigen kleur geven"+getVoorstelFromTemplateName();
        }
    }

    //@#@ miss is het een idee om de default-orientatie keuze op de editpage op de form zelf te zetten == duidelijker + eenvoudigere code

    return (
      <div className="topbars">
        <div className="topbar2">
          
        <div><b> {getPageFunction()}</b></div>

        {props.selectedOrganisation  ? <>
            <span>{props.selectedOrganisation.name}</span>
            <img className="logo" src={props.selectedOrganisation.logos[0]} alt="" /> 
        </>:null}
        </div>
        <div className="topbar_red">
            <span className="nowrap">{ props.page === "edit" || props.page === "create" ? "Voorkeur orientatie scherm(en):"  : 'Orientatie van de scherm(en):' }</span>
            <span className="switch">
                <span className={props.selectedOrientations.local_orientation === "landscape" ? "selected": "not-selected"} onClick={()=>changeOrientationTo('landscape')}>Landscape</span>
                <span className={props.selectedOrientations.local_orientation === "portrait" ? "selected": "not-selected"} onClick={()=>changeOrientationTo('portrait')}>Portrait</span>
            </span>
            {props.page === "show"          ? <Link to={"/"+props.selectedOrganisation.url_name+"/edit"}><div className="style">Huisstijl aanpassen</div></Link>: null}
            {props.page === "edit-template" ? <Link onClick={()=>props.submitFunction()} to={"/"+props.selectedOrganisation.url_name}><div className="style">{props.hasDataChanged ? "Opslaan & ": null }Terug naar lijst</div></Link>: ""}
            {props.page === "edit"          ? <Link onClick={()=>props.submitFunction(isNewOrientationDifferent())} to={"/"+props.selectedOrganisation.url_name}><div className="style">{props.hasDataChanged || isNewOrientationDifferent()? "Opslaan & ": null }Terug naar lijst</div></Link>: ""}
        </div>
      </div>
    );
}


function mapStateToProps(state) {
    return {
        selectedOrientations: state.selectedOrientations,
        selectedOrganisation: state.selectedOrganisation
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            selectOrientations: selectOrientations,
        },
    dispatch
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(Topbar);



