import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './bootstrap.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

// import App from "./App.js";
import {
  Route,
  Routes,
  BrowserRouter as Router
} from "react-router-dom";

import App from "./App.js";
import { Provider } from 'react-redux'
import store from "./store";

import RedirectPage from './pages/redirect-page/redirect-page';
import CreatePage from './pages/create-page/create-page';
import AdminPage from './pages/admin-page/admin-page';


// const store = createStore()

ReactDOM.render(

  <React.StrictMode>
    <Provider store={store}>

      {/* <Topbar1></Topbar1>
            <div className="thinner"> */}
      <Router>
        <Routes>
          <Route path="admin" element={<AdminPage />} />
          <Route path="create" element={<CreatePage />} />
          <Route path={":organisation"} element={<App page="ShowPage" />} />
          <Route exact path={":organisation/edit"} element={<App page="EditPage" />} />
          <Route exact path={":organisation/:template"} element={<App page="EditTemplatePage" />} />

          <Route path={"*"} element={<RedirectPage />} />
        </Routes>
      </Router>
      {/* </div>
             <Bottombar></Bottombar> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// 








