




import React from "react";
import './vmt03.css';
import { getTemplateData, houseData, getStatusColors } from '../../../../pages/global-elements/globals.js'

export default function Vmt03(props) {

    return (
        <div id="vmt-03" className="slide rotation">
            <div className="container" style={{ backgroundColor: props.colors.background_color }}>

                <div className="firsthouse extrapadding">
                    <div className="row no-gutters">
                        <div className="col">
                            <div className="topbar address-details d-flex flex-wrap"
                                style={{ backgroundColor: props.colors.primary_color, color: props.colors.secondary_text_color }}>
                                <span className="street ellipsis">{houseData[0].address}</span>
                                <span className="city ml-auto ellipsis">{props.organisation.location}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-9 nopadding">
                            <div className="status" style={getStatusColors(props.colors, houseData[0].status)}>
                                <span>{houseData[0].status}</span>
                            </div>
                            <div className="overflow">
                                <div
                                >
                                    <div className="shrink-animation house0 bg-img"
                                        style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }}></div>
                                </div>
                                <div className="pricing" style={{ backgroundColor: props.colors.secondary_color }}> 
                                </div>
                                <div className="price" style={{ color: props.colors.tertiary_text_color }}>
                                    {houseData[0].price}
                                </div>
                            </div>
                        </div>
                        <div className="col-3 nopadding">
                            <div >
                                <div className="house1 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}></div>
                            </div>
                            <div >
                                <div className="house2 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }}></div>
                            </div>
                            <div >
                                <div className="house3 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[0].media[3] + ')' }}></div>
                            </div>

                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-12 nopadding">
                            <div className="spacing spacing-md"></div>
                            <div className="details"  style={{ color: props.colors.primary_text_color }}> {/* Was originally secondary_text_color */}
                                <div className="row no-gutters">
                                    <div className="col-7 js-ka-k">{getTemplateData(0, 0)[0]}</div>
                                    <div className="col-5 js-ka-a">{getTemplateData(0, 0)[1]}</div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-7 js-ka-k">{getTemplateData(0, 1)[0]}</div>
                                    <div className="col-5 js-ka-a">{getTemplateData(0, 1)[1]}</div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-7 js-ka-k">{getTemplateData(0, 2)[0]}</div>
                                    <div className="col-5 js-ka-a">{getTemplateData(0, 2)[1]}</div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-7 js-ka-k">{getTemplateData(0, 3)[0]}</div>
                                    <div className="col-5 js-ka-a">{getTemplateData(0, 3)[1]}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row middle">
                    <div className="middlebar d-flex justify-content-center align-items-center"
                        style={{ backgroundColor: props.colors.primary_color }}>
                        <div
                        >
                            <img className="logo" alt="logo" src={props.organisation.logos[props.colors.template_logo_nr]} />
                        </div>
                    </div>
                </div>

                <div className="secondhouse extrapadding">
                    <div className="row no-gutters">
                        <div className="col">
                            <div className="topbar address-details d-flex flex-wrap"
                                style={{ backgroundColor: props.colors.primary_color, color: props.colors.secondary_text_color }}>
                                <span className="street ellipsis">{houseData[1].address}</span><span
                                    className="city ml-auto ellipsis">{props.organisation.location}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-9 nopadding">
                            <div className="status" style={getStatusColors(props.colors, houseData[1].status)}>
                                <span>{houseData[1].status}</span>
                            </div>
                            <div className="overflow">
                                <div
                                >
                                    <div className="shrink-animation house0 bg-img"
                                        style={{ backgroundImage: 'url(' + houseData[1].media[0] + ')' }}></div>
                                </div>
                                <div className="pricing" style={{ backgroundColor: props.colors.secondary_color }}>
                                </div>
                                <div className="price" style={{ color: props.colors.tertiary_text_color }}>
                                    {houseData[1].price}
                                </div>
                            </div>
                        </div>
                        <div className="col-3 nopadding">
                            <div >
                                <div className="house1 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[1].media[1] + ')' }}></div>
                            </div>
                            <div >
                                <div className="house2 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[1].media[2] + ')' }}></div>
                            </div>
                            <div >
                                <div className="house3 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[1].media[3] + ')' }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-12 nopadding">
                            <div className="spacing spacing-md"></div>
                            <div className="details" style={{ color: props.colors.primary_text_color }}> {/* Was originally secondary_text_color */}
                                <div className="row no-gutters">
                                    <div className="col-7 js-ka-k">{getTemplateData(1, 0)[0]}</div>
                                    <div className="col-5 js-ka-a">{getTemplateData(1, 0)[1]}</div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-7 js-ka-k">{getTemplateData(1, 1)[0]}</div>
                                    <div className="col-5 js-ka-a">{getTemplateData(1, 1)[1]}</div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-7 js-ka-k">{getTemplateData(1, 2)[0]}</div>
                                    <div className="col-5 js-ka-a">{getTemplateData(1, 2)[1]}</div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-7 js-ka-k">{getTemplateData(1, 3)[0]}</div>
                                    <div className="col-5 js-ka-a">{getTemplateData(1, 3)[1]}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row bottom">
                    <div className="middlebar d-flex justify-content-center align-items-center"
                        style={{ backgroundColor: props.colors.primary_color }}>
                        {/* <div> */}

                        <img className="logo" alt="logo" src={props.organisation.logos[props.colors.template_logo_nr]} />
                        {/* </div> */}
                    </div>
                </div>

            </div>
            <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
        </div>
    );
}







