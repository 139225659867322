




import React from "react";
import './vmt13.css';
import { getTemplateData, houseData, getStatusColors } from '../../../../pages/global-elements/globals.js'

export default function Vmt13(props) {
    return (

        <div id="vmt-13" className="slide rotation">
            <div className="container" style={{ backgroundColor: props.colors.background_color }}>

                <div className="firsthouse">
                    <div className="row">
                        <div className="col-7 col-left">
                            <div className="address-details d-flex flex-wrap "
                                style={{ backgroundColor: props.colors.primary_color, color: props.colors.secondary_text_color }}>
                                <div className="ellipsis">
                                    <span className="street">{houseData[0].address}</span>

                                    <span className="city ellipsis"> {props.organisation.location}</span>
                                </div>
                            </div>
                            <div className="overflow">
                                <div className="status" style={getStatusColors(props.colors, houseData[0].status)}>
                                    <span>{houseData[0].status}</span>
                                </div>

                                <div
                                >
                                    <div className="shrink-animation house0 bg-img"
                                        style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }}></div>
                                </div>
                            </div>
                            <div className="price-block text-center"
                                style={{ backgroundColor: props.colors.primary_color, color: props.colors.secondary_text_color }}>
                                <div className="price"
                                >
                                    {houseData[0].price}
                                </div>
                            </div>
                        </div>
                        <div className="col-5 col-right">
                            <div className="row no-gutters images">
                                <div className="col-6">
                                    <div className="smallpadding"
                                        style={{ backgroundColor: props.colors.primary_color }}>
                                        <div
                                        >
                                            <div className="house1 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="smallpadding"
                                        style={{ backgroundColor: props.colors.primary_color }}>
                                        <div
                                        >
                                            <div className="house2 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="smallpadding"
                                        style={{ backgroundColor: props.colors.primary_color }}>
                                        <div
                                        >
                                            <div className="house3 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[0].media[3] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="smallpadding"
                                        style={{ backgroundColor: props.colors.primary_color }}>
                                        <div
                                        >
                                            <div className="house4 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[0].media[4] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-gutters details"
                                style={{ color: props.colors.primary_text_color }}>{/* Was originally secondary_text_color */}
                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-7 col-xl-6 js-ka-k">{getTemplateData(0, 0)[0]}</div>
                                        <div className="col-5 col-xl-6 js-ka-a">{getTemplateData(0, 0)[1]}</div>
                                    </div>

                                    <div className="row no-gutters">
                                        <div className="col-7 col-xl-6 js-ka-k">{getTemplateData(0, 1)[0]}</div>
                                        <div className="col-5 colxl-6 js-ka-a">{getTemplateData(0, 1)[1]}</div>
                                    </div>

                                    <div className="row no-gutters">
                                        <div className="col-7 col-xl-6 js-ka-k">{getTemplateData(0, 2)[0]}</div>
                                        <div className="col-5 col-xl-6 js-ka-a">{getTemplateData(0, 2)[1]}</div>
                                    </div>

                                    <div className="row no-gutters">
                                        <div className="col-7 col-xl-6 js-ka-k">{getTemplateData(0, 3)[0]}</div>
                                        <div className="col-5 col-xl-6 js-ka-a">{getTemplateData(0, 3)[1]}</div>
                                    </div>

                                    <div className="row no-gutters">
                                        <div className="col-7 col-xl-6 js-ka-k">{getTemplateData(0, 4)[0]}</div>
                                        <div className="col-5 col-xl-6 js-ka-a">{getTemplateData(0, 4)[1]}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="borderbottom" style={{ backgroundColor: props.colors.secondary_color }}>
                            </div>
                            <div className="logo d-flex justify-content-center align-items-center"  >
                                <img src={props.organisation.logos[props.colors.template_logo_nr]} alt="" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
        </div>

    );
}
