




import React from "react";
import './vmt04.css';
import { getTemplateData, houseData, getStatusColors } from '../../../../pages/global-elements/globals.js'

export default function Vmt04(props) {


    return (
        <div id="vmt-04" className="slide rotation">
            <div className="container" style={{ backgroundColor: props.colors.background_color }}>
                <div className="row">
                    <div className="col-xs-12 p-0">
                        <div className="top-status d-flex justify-content-center align-items-center"
                            style={{ backgroundColor: props.colors.primary_color }}>
                            <div className="logo"
                            >
                                <img alt="logo" src={props.organisation.logos[props.colors.template_logo_nr]} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-6 firsthouse">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="address-details d-flex flex-wrap"
                                    style={{ backgroundColor: props.colors.primary_color, color: props.colors.secondary_text_color }}>
                                    <div className="street ellipsis">{houseData[0].address}</div>

                                    <div className="city ml-auto ellipsis">{props.organisation.location}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-9 p-0">
                                <div className="row no-gutters">
                                    <div className="status-price-bar d-flex"
                                    // style={{ backgroundColor: props.colors.label_color}}
                                    >
                                        <div className="status-block">
                                            <div className="status" style={getStatusColors(props.colors, houseData[0].status)}>
                                                <span>{houseData[0].status}</span>
                                            </div>
                                        </div>
                                        <div className="border mx-2"
                                            style={{ backgroundColor: props.colors.background_color }}></div>
                                        <div className="price px-3"
                                            style={getStatusColors(props.colors, houseData[0].status)}>
                                            <div >
                                                {houseData[0].price}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="overflow">
                                    <div
                                    >
                                        <div className="house0 bg-img"
                                            style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div
                                >
                                    <div className="row no-gutters">
                                        <div className="col-12 p-0">
                                            <div className="house1 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                >
                                    <div className="row no-gutters">
                                        <div className="col-12 p-0">
                                            <div className="house2 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                >
                                    <div className="row no-gutters">
                                        <div className="col-12 p-0">
                                            <div className="house3 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[0].media[3] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 p-0">
                                <div className="details"  style={{ color: props.colors.primary_text_color }}>{/* Was originally secondary_text_color */}
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6 js-ka-k">{getTemplateData(0, 0)[0]}</div>
                                                <div className="col-6 js-ka-a">{getTemplateData(0, 0)[1]}</div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6 js-ka-k">{getTemplateData(0, 1)[0]}</div>
                                                <div className="col-6 js-ka-a">{getTemplateData(0, 1)[1]}</div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6 js-ka-k">{getTemplateData(0, 2)[0]}</div>
                                                <div className="col-6 js-ka-a">{getTemplateData(0, 2)[1]}</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6 js-ka-k">{getTemplateData(0, 3)[0]}</div>
                                                <div className="col-6 js-ka-a">{getTemplateData(0, 3)[1]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 secondhouse">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="address-details d-flex flex-wrap"
                                    style={{ backgroundColor: props.colors.primary_color, color: props.colors.secondary_text_color }}>
                                    <div className="street ellipsis">{houseData[1].address}</div>

                                    <div className="city ml-auto ellipsis">{props.organisation.location}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-9 p-0">
                                <div className="row no-gutters">
                                    <div className="status-price-bar d-flex"
                                    // style={{ backgroundColor: props.colors.label_color}}
                                    >
                                        <div className="status-block">
                                            <div className="status" style={getStatusColors(props.colors, houseData[1].status)}>
                                                <span>{houseData[1].status}</span>
                                            </div>
                                        </div>
                                        <div className="border mx-2"
                                            style={{ backgroundColor: props.colors.background_color }}></div>
                                        <div className="price px-3"
                                            style={getStatusColors(props.colors, houseData[1].status)}>
                                            <div >
                                                {houseData[0].price}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="overflow">
                                    <div
                                    >
                                        <div className="house0 bg-img"
                                            style={{ backgroundImage: 'url(' + houseData[1].media[0] + ')' }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div >
                                    <div className="row no-gutters">
                                        <div className="col-12 p-0">
                                            <div className="house1 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[1].media[1] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                >
                                    <div className="row no-gutters">
                                        <div className="col-12 p-0">
                                            <div className="house2 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[1].media[2] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                >
                                    <div className="row no-gutters">
                                        <div className="col-12 p-0">
                                            <div className="house3 bg-img"
                                                style={{ backgroundImage: 'url(' + houseData[1].media[3] + ')' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 p-0">
                                <div className="details" style={{ color: props.colors.primary_text_color }}>{/* Was originally secondary_text_color */}
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6 js-ka-k">{getTemplateData(1, 0)[0]}</div>
                                                <div className="col-6 js-ka-a">{getTemplateData(1, 0)[1]}</div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6 js-ka-k">{getTemplateData(1, 1)[0]}</div>
                                                <div className="col-6 js-ka-a">{getTemplateData(1, 1)[1]}</div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6 js-ka-k">{getTemplateData(1, 2)[0]}</div>
                                                <div className="col-6 js-ka-a">{getTemplateData(1, 2)[1]}</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6 js-ka-k">{getTemplateData(1, 3)[0]}</div>
                                                <div className="col-6 js-ka-a">{getTemplateData(1, 3)[1]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
        </div>
    );
}







