import React from "react";
import Topbar1 from "../global-elements/topbar1.js";

function redirectPage(props) {
  return (
    <div className="RedirectPage">
      <Topbar1></Topbar1>
      {/* <div style={{height: "calc(100vh - 400px)", textAlign: "center", marginTop: "20px"}}>   Geen geldige pagina :(</div> */}
    </div>
  );
}

export default redirectPage;