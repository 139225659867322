




import React from "react";
import './vmt05.css';
import { getTemplateData, houseData, getStatusColors } from '../../../global-elements/globals.js'

export default function Vmt05(props) {

    return (/*// <!--
    Datum: 14-03-2019
    Notes: Open Huis Changes
--> */
        <div id="vmt-05" className="slide rotation"
            style={{ backgroundColor: props.colors.background_color }}>
            <div className="container">

                <div className="firsthouse">
                    <div className="row">
                        <div className="col-7 col-left extrapadding">
                            <div className="logo">
                                <img src={props.organisation.logos[props.colors.template_logo_nr]} alt="logo" />
                            </div>
                            <div className="overflow position-relative">
                                <div className="status" style={getStatusColors(props.colors, houseData[0].status)}>
                                    <span>{houseData[1].status}</span>
                                </div>
                                <div
                                >
                                    <div className="shrink-animation house0 bg-img"
                                        style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }}></div>
                                </div>
                            </div>
                            <div className="row no-gutters justify-content-between">
                                <div className="col-4"
                                >
                                    <div className="house1 bg-img"
                                        style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}></div>
                                </div>
                                <div className="col-4"
                                >
                                    <div className="house2 bg-img"
                                        style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }}></div>
                                </div>
                                <div className="col-4"
                                >
                                    <div className="house3 bg-img"
                                        style={{ backgroundImage: 'url(' + houseData[0].media[3] + ')' }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 col-right extrapadding">
                            <div className="address-details d-flex flex-wrap"
                                style={{ color: props.colors.primary_text_color }}>
                                <div className="street ellipsis">{houseData[0].address}</div>

                                <div className="city ellipsis">{props.organisation.location}</div>
                            </div>
                            <div className="border" style={{ backgroundColor: props.colors.secondary_color }}></div>
                            <div >
                                <div className="description" id="description" style={{ color: props.colors.primary_text_color }}>
                                    {/* <p>{{ slide.data[0].description }}</p> @#@ */}
                                    <p>Ben je op zoek naar een instapklare en vrijstaande woning? Zoek dan niet langer, deze woning is echt iets voor jou! Deze woning is gebouwd in 2018 en kent daardoor een moderne en duurzame vormgeving.  </p>
                                </div>
                                <div className="border" style={{ backgroundColor: props.colors.secondary_color }}>
                                </div>
                            </div>
                            <div className="details" style={{ color: props.colors.primary_text_color }}>
                                <div className="row no-gutters">

                                    <div className="col-6">
                                        <div className="row no-gutters">
                                            <div className="col-7 p-0 js-ka-k">{getTemplateData(0, 0)[0]}</div>
                                            <div className="col-5 p-0 js-ka-a">{getTemplateData(0, 0)[1]}</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row no-gutters">
                                            <div className="col-7 p-0 js-ka-k">{getTemplateData(0, 1)[0]}</div>
                                            <div className="col-5 p-0 js-ka-a">{getTemplateData(0, 1)[1]}</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row no-gutters">
                                            <div className="col-7 p-0 js-ka-k">{getTemplateData(0, 2)[0]}</div>
                                            <div className="col-5 p-0 js-ka-a">{getTemplateData(0, 2)[1]}</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row no-gutters">
                                            <div className="col-7 p-0 js-ka-k">{getTemplateData(0, 3)[0]}</div>
                                            <div className="col-5 p-0 js-ka-a">{getTemplateData(0, 3)[1]}</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row no-gutters">
                                            <div className="col-7 p-0 js-ka-k">{getTemplateData(0, 4)[0]}</div>
                                            <div className="col-5 p-0 js-ka-a">{getTemplateData(0, 4)[1]}</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row no-gutters">
                                            <div className="col-7 p-0 js-ka-k">{getTemplateData(0, 5)[0]}</div>
                                            <div className="col-5 p-0 js-ka-a">{getTemplateData(0, 5)[1]}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border" style={{ backgroundColor: props.colors.secondary_color }}></div>
                            <div className="col-xs-12 p-0"
                                style={{ color: props.colors.primary_text_color }}>
                                {/* <div className="ask-price">{{
                            slide.data[0].sale_type == '2' ? 'Huurprijs:' : 'Vraagprijs:' }}</div>@#@ */}
                                <div className="ask-price">Vraagprijs:</div>
                                <div className="price">
                                    <div >
                                        {houseData[0].price}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ backgroundColor: props.colors.primary_color }}>
                        <div className="col-xs-12 p-0">
                            <div className="footer" style={{ backgroundColor: props.colors.primary_color }}>
                                <div className="orange pull-left"
                                    style={{ backgroundColor: props.colors.secondary_color }}></div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
        </div>
    );
}







