




import React from "react";
import './vmts00.css';
import { getTemplateData, houseData, getStatusColors } from '../../../../pages/global-elements/globals.js';

export default function Vmts00(props) {

    return (
        <div id="vmts-00" className="slide rotation">
            {/* <!-- <meta *ngIf="globals.orientation == 'landscape'" name="viewport" content="width=1920px"> 
    <meta *ngIf="globals.orientation == 'portrait'" name="viewport" content="width=1080px">  --> */}
            <div className="container">

                <div className="header-slide" style={{ backgroundColor: props.colors.primary_color }}>
                    <img className="logo" src={props.organisation.logos[props.colors.template_logo_nr]} alt="" />
                </div>

                <div className="content-slide">
                    <div className="house">
                        <div className="main-picture">
                            <div className="status" style={getStatusColors(props.colors, houseData[0].status)}>
                                <span>{houseData[0].status}</span>
                            </div>

                            <div className="bg-img" style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }} ></div>
                        </div>

                        <div className="text-container">
                            <div className="address" style={{ color: props.colors.primary_text_color }}>
                                <span>
                                    {houseData[0].address}, </span>
                                <span>
                                    {props.organisation.location}</span>
                            </div>
                            <div className="price" style={{ color: props.colors.primary_text_color }}>      
                                <div>
                                    {houseData[0].price}
                                </div>
                            </div>

                            <table style={{ color: props.colors.primary_text_color }}>{/* Was originally tertiary_text_color */}
                                <tbody>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(0, 0)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(0, 0)[1]}</td>
                                    </tr>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(0, 1)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(0, 1)[1]}</td>
                                    </tr>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(0, 2)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(0, 2)[1]}</td>
                                    </tr>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(0, 3)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(0, 3)[1]}</td>
                                    </tr>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(0, 4)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(0, 4)[1]}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="bottom-pictures">
                        <div className="s-img" style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }} ></div>
                        <div className="s-img" style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }} ></div>
                        <div className="s-img" style={{ backgroundImage: 'url(' + houseData[0].media[3] + ')' }} ></div>
                        <div className="s-img" style={{ backgroundImage: 'url(' + houseData[0].media[4] + ')' }} ></div>
                    </div>
                </div>
                {/* <img className="poweredByViewie" src="assets/templates/viewie-media-label.svg" alt=""/> */}
            </div>
            <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
        </div>
    );
}