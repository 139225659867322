import React, {useState} from "react";
import Topbar from "../global-elements/topbar.js"
import Topbar1 from "../global-elements/topbar1.js"
import Bottombar from "../global-elements/bottombar.js"

import './form.css';
import './create-page.css';
import axios from 'axios';
import { connect } from "react-redux";
import {possibleFontsArray} from '../global-elements/globals.js';
import ColorSelector from '../global-elements/color-selector/color-selector.js';

function CreatePage(props) {
    const [url_name, setUrlName] = useState("");
    const [baseImage, setBaseImage] = useState("");
    const [baseImage2, setBaseImage2] = useState("");
    const [baseImage3, setBaseImage3] = useState("");
    // 3x baseImage not ideal

    const [isTransportPage, setIsTransportPage] = useState(false);
    const [futureStyling, setFutureStyling] = useState({
        template_logo_nr: "0",
        primary_color: "#BABABA",
        secondary_color: "#FFFFFF",
        tertiary_color: "#FFFFFF",
    
        primary_text_color: "#000000",
        secondary_text_color: "#000000",
        tertiary_text_color: "#000000",
        
        background_color: "#FFFFFF",
        font: "Open Sans",
        label_forsale_color: "#71c142",
        label_forsale_text_color: "#FFFFFF",
        label_sold_color: "#b71616",
        label_sold_text_color: "#FFFFFF"
    });

    const form = document.getElementById('js-create-form');

    const uploadImage = async (e,i) => {
        if(!e.target.files[0]) return;
        if(e.target.files[0].size > 1000000) alert('Deze afbeelding lijkt onnodig groot, even compressen?')
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        if(i===0)setBaseImage(base64);
        else if(i===1)setBaseImage2(base64);
        else if(i===2)setBaseImage3(base64);
        console.log(e.target.files[0].size)
        console.log(base64)
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    const urlChangeHandler = () => {
        let name = document.getElementById("name").value;
        let location = document.getElementById('location').value;
        setUrlName((name+ "_" + location).replace(/\s+/g, '_').toLocaleLowerCase()  )
    }




    async function handleSubmit() {
        axios.get(`https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/organisations/${url_name}.json`).then(response => {
            if(response.data === null){
                write2Api();            
            }
            else{
                if(window.confirm('\n \t WAARSCHUWING!!!!  \t \n \n Organisatie bestaat al, wilt u deze overschrijven?\n')){
                    write2Api();
                }
            }
        }, error => {
            alert('Er gaat iets fout, zijn alle velden ingevuld?')
            console.log(error);
        });     
    };

 

    function write2Api(){
  
        function findEl(nam){
            return form.querySelector('input[name="'+nam+'"]').value;
        }
        
        function capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        function getCurrentDate(){
            var date = new Date();            
            return date.getDate()+"-"+(date.getMonth()+1)+"-"+date.getFullYear();
        }

        axios.all([
            axios.patch(`https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/organisations/${url_name}.json`,{
                organisation:{
                    name: capitalize(findEl('name')),
          	        location: capitalize(findEl('location')),
                    logos: [ baseImage, baseImage2, baseImage3],
                    url_name: url_name
                },  
                saved_orientation: props.selectedOrientations.saved_orientation,
                styling: futureStyling,
                activated_template: [],
                custom_template_colors:{}
            }),
            axios.patch('https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/organisation_list.json',{
                [url_name]: getCurrentDate()
            })       
        ]).then(function(res) {
            setIsTransportPage(true);
        }).catch(function(error) {
            // error response flow
            alert("Organisatie opslaan mislukt")
            console.log(error.response);
        });
    }

   
    const getPossibleFontsArray = possibleFontsArray.map((font, i) =>
        <option style={{fontFamily: font}} key={i} value={font}>
            {font}
        </option>
    );

    const updateStylingFieldFunction = (valueName, newValue) => {
        setFutureStyling({...futureStyling,  [valueName] : newValue})
    }


  return (
    <div className="CreatePage App">
        {isTransportPage ?
            <div className="confirmTransport">
                <div> Organisatie succesvol aangemaakt en opgeslagen, klik op de volgende link om de bijhorende pagina te zien</div>
                <br />
                <a style={{color:'blue'}} href={"/"+url_name}>{window.location.hostname+"/"+url_name}</a>
                 <a className="back2create"  href={"/create"}>Terug</a>
            </div>
            :
            <>
                <Topbar1></Topbar1>
                <div className="thinner">

                    <Topbar page="create"></Topbar>

                    <form className="form" id="js-create-form" onSubmit={handleSubmit}>
                   
                         <div className="form-block pt50">
                            <div className="inner-form-block">
                                <div className="styling-title">Logo's en URL
                                 <div className="styling-undertitle">Door de logo's te compressen nemen ze minder ruimte op en kunnen de templates sneller laden</div></div>
                                <div className="logo-block">
                                    <div className="previewContainer">
                                        <div className="previewTitle">Logo 1 (Witte achtergrond)</div>
                                        <img className="preview" alt="" src={baseImage} onClick={()=>document.getElementById('fileUpload1').click()}/>
                                        <div className="greyBlockForFireFoxSafari" onClick={()=>document.getElementById('fileUpload1').click()}></div>

                                        <input type="file" id="fileUpload1" name ="normal" onChange={(e) => {uploadImage(e, 0);}} accept="image/png, image/gif, image/jpeg, image/svg+xml"/>
                                    </div>
                                    <div  className="previewContainer">
                                        <div className="previewTitle">Logo 2 (Gekleurde achtergrond)</div>
                                        <img className="preview" alt="" src={baseImage2} onClick={()=>document.getElementById('fileUpload2').click()}/>
                                        <div className="greyBlockForFireFoxSafari" onClick={()=>document.getElementById('fileUpload2').click()}></div>
                                        <input type="file" id="fileUpload2" name="diapositief" onChange={(e) => {uploadImage(e, 1);}} accept="image/png, image/gif, image/jpeg, image/svg+xml"/>
                                    </div>
                                    <div className="previewContainer">
                                        <div className="previewTitle">Logo 3 (Grijze achtergrond)</div>
                                        <img className="preview" alt="" src={baseImage3} onClick={()=>document.getElementById('fileUpload3').click()}/>
                                        <div className="greyBlockForFireFoxSafari" onClick={()=>document.getElementById('fileUpload3').click()}></div>

                                        <input type="file" id="fileUpload3" name ="third" onChange={(e) => {uploadImage(e, 2);}} accept="image/png, image/gif, image/jpeg, image/svg+xml"/>
                                    </div>
                                </div>
                                <input type="text" onChange={urlChangeHandler} placeholder="Naam organisatie" id="name" name="name" />
                                <input type="text" onChange={urlChangeHandler} placeholder="Locatie organisatie" id="location" name="location" /> 

                                <div className="o4 url">/{url_name}</div>
                            </div>
                        </div> 
                        {/* make inputs for easy # colors */}
                         <div className="form-block">
                             <div className="inner-form-block">
                                <div className="styling-title">Kleuren & Tekst
                                   <div className="styling-undertitle">Deze 6 kleuren kan een klant ook nog los instellen per template</div>   
                                </div>
                            
                                <ColorSelector id="primary_color" name="Primaire kleur" value={futureStyling.primary_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="primary_text_color" name="Primaire tekst kleur (text donker)" value={futureStyling.primary_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="secondary_color" name="Secondaire kleur" value={futureStyling.secondary_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="secondary_text_color" name="Secondaire tekst kleur (tekst kleur)" value={futureStyling.secondary_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="tertiary_color" name="Tertiare kleur" value={futureStyling.tertiary_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="tertiary_text_color" name="Tertiare tekst kleur (tekst licht)" value={futureStyling.tertiary_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                            </div>


                        </div>
                        <div className="form-block">
                            <div className="inner-form-block">
                                <div className="styling-title">Overige styling</div>
                                <ColorSelector id="background_color" name="Achtergrond kleur" value={futureStyling.background_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>                  
                                <select name="font" id="font" defaultValue="Open Sans" onChange={e=> updateStylingFieldFunction('font', e.target.value)}>
                                    {getPossibleFontsArray}
                                </select>
                          </div>
                        </div>
                        <div className="form-block">
                            <div className="inner-form-block">
                                <div className="styling-title">Kleuren status</div>
                                <ColorSelector id="label_forsale_color" name="'Te koop' achtergrond" value={futureStyling.label_forsale_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="label_forsale_text_color" name="'Te koop' tekst" value={futureStyling.label_forsale_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="label_sold_color" name="'Verkocht' achtergrond" value={futureStyling.label_sold_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="label_sold_text_color" name="'Verkocht' tekst" value={futureStyling.label_sold_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                 
                            </div>
                        </div>

                      


                        <div className="button submit" onClick={()=>handleSubmit()}>Maak organisatie aan</div>
                    </form>
                </div>
                <Bottombar></Bottombar>
            </>
        }

       
    </div>
  );
}

function mapStateToProps(state) {
    return {
        selectedOrientations: state.selectedOrientations,
    }
}

export default connect(mapStateToProps)(CreatePage);