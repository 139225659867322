import React, { useState } from "react";
import { bindActionCreators } from "redux";
import Topbar from "../global-elements/topbar.js"

import './edit-template-page.css';
import axios from 'axios';
import { connect } from "react-redux";

import { selectCustomColors } from "../../store/actions";
import Vmts00 from '../show-page/templates/vmts00/vmts00.js';
import Vmts01 from '../show-page/templates/vmts01/vmts01.js';
import Vmts02 from '../show-page/templates/vmts02/vmts02.js';
import Vmts03 from '../show-page/templates/vmts03/vmts03.js';
import Vmts04 from '../show-page/templates/vmts04/vmts04.js';
import Vmt00 from '../show-page/templates/vmt00/vmt00.js';
import Vmt01 from '../show-page/templates/vmt01/vmt01.js';
import Vmt02 from '../show-page/templates/vmt02/vmt02.js';
import Vmt03 from '../show-page/templates/vmt03/vmt03.js';
import Vmt04 from '../show-page/templates/vmt04/vmt04.js';
import Vmt05 from '../show-page/templates/vmt05/vmt05.js';
import Vmt09 from '../show-page/templates/vmt09/vmt09.js';
import Vmt10 from '../show-page/templates/vmt10/vmt10.js';
import Vmt13 from '../show-page/templates/vmt13/vmt13.js';
import Vmt15 from '../show-page/templates/vmt15/vmt15.js';
import Vmt49 from '../show-page/templates/vmt49/vmt49.js';
import { getDefaultLogo, possibleTemplatesInOrder } from "../global-elements/globals.js"
import ColorSelector from '../global-elements/color-selector/color-selector.js';

function EditTemplatePage(props) {
    const template = window.location.pathname.split("/").pop();
    const [localTemporaryColors, setLocalTemporaryColors] = useState(initLocalTemporaryColors());
    const [hasDataChanged, setHasDataChanged] = useState(false);
    const [isTemplateCutLocal, setIsTemplateCutLocal] = useState(typeof props.selectedCustomColors[template] == 'object')

    const updateStylingFieldFunction = (valueName, newValue) => {
        setLocalTemporaryColors({ ...localTemporaryColors, [valueName]: newValue })
    }

    // Deze kan blijven
    function initLocalTemporaryColors() {
        let futureLocalStyling = JSON.parse(JSON.stringify(props.selectedStyling));
        if (props.selectedCustomColors[template]) {
            futureLocalStyling.template_logo_nr = props.selectedCustomColors[template].template_logo_nr;
            futureLocalStyling.primary_color = props.selectedCustomColors[template].primary_color;
            futureLocalStyling.secondary_color = props.selectedCustomColors[template].secondary_color;
            futureLocalStyling.tertiary_color = props.selectedCustomColors[template].tertiary_color;
            futureLocalStyling.primary_text_color = props.selectedCustomColors[template].primary_text_color;
            futureLocalStyling.secondary_text_color = props.selectedCustomColors[template].secondary_text_color;
            futureLocalStyling.tertiary_text_color = props.selectedCustomColors[template].tertiary_text_color;
            return futureLocalStyling;
        }
        futureLocalStyling.template_logo_nr = getDefaultLogo(template);
        return futureLocalStyling;
    }

    function getTemplateShow() {
        switch (template.toLocaleLowerCase()) {
            case "vmts00": return <Vmts00 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmts00>;
            case "vmts01": return <Vmts01 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmts01>;
            case "vmts02": return <Vmts02 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmts02>;
            case "vmts03": return <Vmts03 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmts03>;
            case "vmts04": return <Vmts04 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmts04>;

            case "vmt00": return <Vmt00 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt00>;
            case "vmt01": return <Vmt01 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt01>;
            case "vmt02": return <Vmt02 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt02>;
            case "vmt03": return <Vmt03 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt03>;
            case "vmt04": return <Vmt04 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt04>;
            case "vmt05": return <Vmt05 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt05>;
            case "vmt09": return <Vmt09 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt09>;
            case "vmt10": return <Vmt10 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt10>;
            case "vmt13": return <Vmt13 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt13>;
            case "vmt15": return <Vmt15 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt15>;
            case "vmt49": return <Vmt49 colors={localTemporaryColors} organisation={props.selectedOrganisation}></Vmt49>;
            default: return <div style={{ width: "1920px", height: "1080px", fontSize: "50px" }}>Template niet gevonden</div>;
        }
    }




    function handleSubmit() {
        if (!hasDataChanged) return;
        let templateid = window.location.href.split("/").pop();

        // if(!possibleTemplatesInOrder.includes(templateid)) return null; 

        props.selectCustomColors({ ...props.selectedCustomColors, [templateid]: localTemporaryColors });

        if (isTemplateCutLocal) {
            axios({
                method: 'patch',
                url: `https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/organisations/${props.organisation_url}/custom_template_colors/${templateid}.json`,
                data: {
                    template_logo_nr: localTemporaryColors.template_logo_nr,
                    primary_color: localTemporaryColors.primary_color,
                    secondary_color: localTemporaryColors.secondary_color,
                    tertiary_color: localTemporaryColors.tertiary_color,

                    primary_text_color: localTemporaryColors.primary_text_color,
                    secondary_text_color: localTemporaryColors.secondary_text_color,
                    tertiary_text_color: localTemporaryColors.tertiary_text_color
                },
            }).then(function (res) {
                // successful response flow
                // console.log(res);
            }).catch(function (error) {
                // error response flow
                alert("Template loskoppelen mislukt")
                console.log(error.response);
            });
        }
        else {
            // if(window.confirm('Wilt u deze template weer koppelen aan uw huisstijl?')){
            let templateid = window.location.href.split("/").pop();
            setLocalTemporaryColors(props.selectedStyling)
            props.selectCustomColors({ ...props.selectedCustomColors, [templateid]: undefined });
            axios.delete(`https://viewie-demo-app-default-rtdb.europe-west1.firebasedatabase.app/organisations/${props.organisation_url}/custom_template_colors/${templateid}.json`)
            // }
        }

    };
    function localReset2HouseStyle() {
        if (window.confirm('Wilt u deze template weer koppelen aan uw huisstijl?')) {
            setIsTemplateCutLocal(false);
            setHasDataChanged(true);

            let originalObj = props.selectedStyling;
            originalObj.template_logo_nr = getDefaultLogo(template);//Needed because organisation does not have a default logo for everything
            setLocalTemporaryColors(originalObj) 
        }
    }
    function cutTemplateLooseLocal() {
        setHasDataChanged(true);
        setIsTemplateCutLocal(true);
    }

    // function returnScale(){
    //     return (window.innerWidth - 200) / 1920;
    // }@#@

    return (
        <div className="EditTemplatePage">
            <Topbar page="edit-template" templateName={template.toLocaleLowerCase()} submitFunction={handleSubmit} hasDataChanged={hasDataChanged}></Topbar>
            <form className="form" id="form-edit-template" onChange={() => setHasDataChanged(true)}>

                <div className={props.selectedOrientations.local_orientation === "portrait" ? "form-block preview-container pt-65 portrait" : "form-block preview-container pt-65 landscape"}>
                    <div className={props.selectedOrientations.local_orientation === "portrait" ? "templateContainer portrait" : "templateContainer landscape"} style={{ fontFamily: props.selectedStyling.font }}>

                        <div className="center"> {getTemplateShow()}</div>
                    </div>
                </div>
                {/* <input type="submit" value="Opslaan" /> */}

                {possibleTemplatesInOrder.includes(template) ?
                    <>
                        <div className="form-block" style={{ display: isTemplateCutLocal ? "block" : "none" }}>
                            <div className="inner-form-block">
                                <div className="styling-title">Custom kleuren & Tekst
                                    <div className="styling-undertitle">De overige kleuren blijven wel blijven wel altijd gekoppeld aan de huisstijl</div>
                                </div>

                                {/* <span>  
                            <label htmlFor="primary_color">Primaire kleur</label> 
                            <input onChange={changeHandler}  type="color"  name="primary_color" defaultValue={localTemporaryColors.primary_color}/>
                        </span>
*/}

                                <ColorSelector id="primary_color" name="Primaire kleur" value={localTemporaryColors.primary_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="primary_text_color" name="Primaire tekst kleur" value={localTemporaryColors.primary_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="secondary_color" name="Secondaire kleur" value={localTemporaryColors.secondary_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="secondary_text_color" name="Secondaire tekst kleur" value={localTemporaryColors.secondary_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="tertiary_color" name="Tertiare kleur" value={localTemporaryColors.tertiary_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>
                                <ColorSelector id="tertiary_text_color" name="Tertiare tekst kleur" value={localTemporaryColors.tertiary_text_color} updateStylingFieldFunction={updateStylingFieldFunction}></ColorSelector>

                                 <select name="template_logo_nr" id="template_logo_nr" value={localTemporaryColors.template_logo_nr} onChange={e=> setLocalTemporaryColors({ ...localTemporaryColors, 'template_logo_nr': e.target.value })}> 
                                    <option value="0">Standaard logo: 1</option>
                                    {props.selectedOrganisation.logos.length > 1 ? <option value="1">Standaard logo: 2</option> : null}
                                    {props.selectedOrganisation.logos.length > 2 ? <option value="2">Standaard logo: 3</option> : null}
                                </select>
                            </div>
                        </div>


                        <div className="buttonsContainer" >

                            {isTemplateCutLocal ?
                                <>
                                    {/* <div className="button submit-small" onClick={()=>handleSubmit()}>Aanpassingen opslaan</div> */}
                                    <div className="button reset" onClick={() => localReset2HouseStyle()}> Reset naar huisstijl</div>
                                    {/* class="button reset" */}
                                </>
                                :
                                <div className="button submit-small" style={{ gridColumn: '1/3' }} onClick={() => cutTemplateLooseLocal()}>Loskoppelen van huisstijl</div>
                            }
                        </div>
                    </>
                    : null}

            </form>

        </div>
    );
}
// const mapStateToProps = state  => { 
//     return {
//         selectedOrientations: state.selectedOrientations,
//         selectedOrganisation: state.selectedOrganisation,
//         selectedStyling: state.selectedStyling,
//         selectedCustomColors: state.selectedCustomColors
//     }
// }; 

function mapStateToProps(state) {
    return {
        selectedOrientations: state.selectedOrientations,
        selectedOrganisation: state.selectedOrganisation,
        selectedStyling: state.selectedStyling,
        selectedCustomColors: state.selectedCustomColors
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            selectCustomColors: selectCustomColors,
        },
        dispatch
    );
}
export default connect(mapStateToProps, matchDispatchToProps)(EditTemplatePage);
