




import React from "react";
import './vmt00.css';
import { getTemplateData, houseData, getStatusColors } from '../../../../pages/global-elements/globals.js';

export default function Vmt01(props) {
    return (

        <div id="vmt-00" className="slide rotation">
            <div className="container" style={{ backgroundColor: props.colors.background_color }}>
                <div className="firsthouse">
                    <div className="row">
                        <div className="col p-0">

                            <div >
                                <div className="shrink-animation house1 bg-img"
                                    style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }}></div>
                            </div>
                            <div className="status" style={getStatusColors(props.colors, houseData[0].status)}>
                                <span>{houseData[0].status}</span>
                            </div>

                        </div>
                        <div className="col d-flex flex-wrap p-0">
                            <div className="house2 bg-img"
                                style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}></div>

                            <div className="house3 bg-img"
                                style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }}></div>

                            <div className="house4 bg-img"
                                style={{ backgroundImage: 'url(' + houseData[0].media[3] + ')' }}></div>

                            <div className="house5 bg-img"
                                style={{ backgroundImage: 'url(' + houseData[0].media[4] + ')' }}></div>

                        </div>
                        {/* <div className="house6 bg-img" style={{backgroundImage: 'url(' + houseData[0].media[0] + ')'}}>
                </div> */}
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex flex-wrap p-0">
                            <div className="col-12 col-9 order-2 order-xl-1 p-0">
                                <div className="address-details" style={{ borderColor: props.colors.primary_color }}>
                                    <div className="d-flex" style={{ color: props.colors.primary_text_color }}>
                                        <span className="street ellipsis">{houseData[0].address},</span>

                                        <span className="city ellipsis">{props.organisation.location}</span>
                                    </div>
                                    <div className="price d-block"
                                        style={{ color: props.colors.primary_text_color }}>
                                        <div>
                                            {houseData[0].price}
                                        </div>
                                    </div>
                                </div>
                                <div className="details" style={{ color: props.colors.primary_text_color }}>
                                    <div className="row">

                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div><strong className="js-ka-k">{getTemplateData(0, 0)[0]}</strong></div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="js-ka-a">{getTemplateData(0, 0)[1]}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div><strong className="js-ka-k">{getTemplateData(0, 1)[0]}</strong></div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="js-ka-a">{getTemplateData(0, 1)[1]}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div><strong className="js-ka-k">{getTemplateData(0, 2)[0]}</strong></div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="js-ka-a">{getTemplateData(0, 2)[1]}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div><strong className="js-ka-k">{getTemplateData(0, 3)[0]}</strong></div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="js-ka-a">{getTemplateData(0, 3)[1]}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div><strong className="js-ka-k">{getTemplateData(0, 4)[0]}</strong></div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="js-ka-a">{getTemplateData(0, 4)[1]}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div><strong className="js-ka-k">{getTemplateData(0, 5)[0]}</strong></div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="js-ka-a">{getTemplateData(0, 5)[1]}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">


                        <div className="leftFooterPart" style={{ backgroundColor: props.colors.primary_color }}></div>
                        <div className="rightFooterPart" style={{ backgroundColor: props.colors.secondary_color }}>
                            <div className="rightFooterPartSide"
                                style={{ backgroundColor: props.colors.secondary_color }}></div>
                        </div>
                        <div className="logo">
                            <div>
                                <img src={props.organisation.logos[props.colors.template_logo_nr]} alt="logo" />
                            </div>
                        </div>
                    </footer>
                </div>

            </div>
            <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
        </div>
    );
}



