

import React from "react";
import './vmts01.css';
import { getTemplateData, houseData, getStatusColors } from '../../../../pages/global-elements/globals.js';


function Vmts01(props) {
    return (
        <div id="vmts-01" className="slide rotation">
            {/* <!-- <meta *ngIf="globals.orientation == 'landscape'" name="viewport" content="width=1920px">  -->
    <!-- <meta *ngIf="globals.orientation == 'portrait'" name="viewport" content="width=1080px">  --> */}


            <div className="container">
                <div className="header-slide" style={{ backgroundColor: props.colors.primary_color }}>
                    <img className="logo" src={props.organisation.logos[props.colors.template_logo_nr]} alt="" />
                </div>
                <div className="content-slide">

                    {/* <!-- HOUSE 00 --> */}
                    <div className="house">
                        <div className="picture-container">
                            <div className="main-picture">
                                <div className="status for-rent" style={getStatusColors(props.colors, houseData[0].status)}>
                                    <span>{houseData[0].status}</span>
                                </div>

                                <div className="bg-img" style={{ backgroundImage: 'url(' + houseData[0].media[0] + ')' }}></div>
                            </div>

                            <div className="side-pictures">
                                <div className="s-img s-img-top"
                                    style={{ backgroundImage: 'url(' + houseData[0].media[1] + ')' }}></div>
                                <div className="s-img s-img-bottom"
                                    style={{ backgroundImage: 'url(' + houseData[0].media[2] + ')' }}></div>
                            </div>
                        </div>
                        <div className="text-container">
                            <div className="address" style={{ color: props.colors.primary_text_color }} >
                                <span>{houseData[0].address}, </span>
                                <span>
                                    {" " + props.organisation.location}</span>
                            </div>
                            <div className="price" style={{ color: props.colors.primary_text_color }}>
                                <div>
                                    {houseData[0].price}
                                </div>
                            </div>
                            {/* <div className="price" [ngStyle]="{ 'color': globals.organisation.secondary_text_color }"
                        *ngIf="(slide.data[0].price !== undefined && slide.data[0].price !== null && slide.data[0].price !== '0' ) || slide.data[0].price_type == 'prijs_op_aanvraag'">
                        <div *ngIf="slide.data[0].price_type !== 'prijs_op_aanvraag'">
                            € {{ slide.data[0].price }},-
                            <div className="d-inline-block">
                                <div *ngIf="slide.data[0].sale_condition == 1 ">k.k.</div>
                                <div *ngIf="slide.data[0].sale_condition == 2 ">v.o.n.</div>
                                <div *ngIf="slide.data[0].sale_condition == 3 ">p/m</div>
                                <div *ngIf="slide.data[0].sale_condition == 4 ">per kwartaal</div>
                                <div *ngIf="slide.data[0].sale_condition == 5 ">per half jaar</div>
                                <div *ngIf="slide.data[0].sale_condition == 6 ">per jaar</div>
                                <div *ngIf="slide.data[0].sale_condition == 7 ">per contract</div>
                                <div *ngIf="slide.data[0].sale_condition == 8 ">per m2 p/m</div>
                                <div *ngIf="slide.data[0].sale_condition == 9 ">per m2 p/j</div>
                            </div>
                        </div>
                        <div *ngIf="slide.data[0].price_type == 'prijs_op_aanvraag'">
                            Prijs op aanvraag
                        </div>
                    </div> */}
                            <table style={{ color: props.colors.primary_text_color }}>{/* Was originally tertiary_text_color */}
                                <tbody>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(0, 0)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(0, 0)[1]}</td>
                                    </tr>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(0, 1)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(0, 1)[1]}</td>
                                    </tr>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(0, 2)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(0, 2)[1]}</td>
                                    </tr>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(0, 3)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(0, 3)[1]}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <!-- HOUSE 00 END --> */}

                    {/* <!-- HOUSE 01 --> */}
                    <div className="house">
                        <div className="picture-container">
                            <div className="main-picture">
                                <div className="status for-rent" style={getStatusColors(props.colors, houseData[1].status)}>
                                    <span>{houseData[1].status}</span>
                                </div>

                                <div className="bg-img" style={{ backgroundImage: 'url(' + houseData[1].media[0] + ')' }}></div>
                            </div>

                            <div className="side-pictures">
                                <div className="s-img s-img-top"
                                    style={{ backgroundImage: 'url(' + houseData[1].media[1] + ')' }}></div>
                                <div className="s-img s-img-bottom"
                                    style={{ backgroundImage: 'url(' + houseData[1].media[2] + ')' }}></div>
                            </div>
                        </div>
                        <div className="text-container">
                            <div className="address" style={{ color: props.colors.primary_text_color }} >
                                <span>{houseData[1].address}, </span>
                                <span>
                                    {" " + props.organisation.location}</span>
                            </div>
                            <div className="price" style={{ color: props.colors.primary_text_color }}>
                                <div>
                                    {houseData[1].price}
                                </div>
                            </div>
                            {/* <div className="price" [ngStyle]="{ 'color': globals.organisation.secondary_text_color }"
                        *ngIf="(slide.data[0].price !== undefined && slide.data[0].price !== null && slide.data[0].price !== '0' ) || slide.data[0].price_type == 'prijs_op_aanvraag'">
                        <div *ngIf="slide.data[0].price_type !== 'prijs_op_aanvraag'">
                            € {{ slide.data[0].price }},-
                            <div className="d-inline-block">
                                <div *ngIf="slide.data[0].sale_condition == 1 ">k.k.</div>
                                <div *ngIf="slide.data[0].sale_condition == 2 ">v.o.n.</div>
                                <div *ngIf="slide.data[0].sale_condition == 3 ">p/m</div>
                                <div *ngIf="slide.data[0].sale_condition == 4 ">per kwartaal</div>
                                <div *ngIf="slide.data[0].sale_condition == 5 ">per half jaar</div>
                                <div *ngIf="slide.data[0].sale_condition == 6 ">per jaar</div>
                                <div *ngIf="slide.data[0].sale_condition == 7 ">per contract</div>
                                <div *ngIf="slide.data[0].sale_condition == 8 ">per m2 p/m</div>
                                <div *ngIf="slide.data[0].sale_condition == 9 ">per m2 p/j</div>
                            </div>
                        </div>
                        <div *ngIf="slide.data[0].price_type == 'prijs_op_aanvraag'">
                            Prijs op aanvraag
                        </div>
                    </div> */}
                            <table style={{ color: props.colors.primary_text_color }}>{/* Was originally tertiary_text_color */}
                                <tbody>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(1, 0)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(1, 0)[1]}</td>
                                    </tr>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(1, 1)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(1, 1)[1]}</td>
                                    </tr>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(1, 2)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(1, 2)[1]}</td>
                                    </tr>
                                    <tr>
                                        <th className="js-ka-k">{getTemplateData(1, 3)[0]}</th>
                                        <td className="js-ka-a">{getTemplateData(1, 3)[1]}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>
            <img className="poweredByViewie" src={require('../../../../assets/viewie-media-label.svg')} alt=""/>
        </div>

    );
}



export default Vmts01;
